import { Box, Container, useMediaQuery, } from '@mui/material';
import { BreadCrumbs, } from 'components/BreadCrumbs';
import { HTag, } from 'components/HTag';
import { NewsList, } from 'components/NewsList';
import PaginationComponent from 'components/UI/Pagination/PaginationComponent';
import React, { useEffect, useState, } from 'react';
import { useTranslation, } from 'react-i18next';
import { useDispatch, useSelector, } from 'react-redux';
import { getInformation, } from 'redux-api/slices/informationSlice';

import classes from './information.module.scss';

const Information = () => {
  const dispatch = useDispatch();

  const { count, } = useSelector(state => state.information.information);

  const { t, } = useTranslation();
  const [ page, setPage, ] = useState(1);
  const offset = 9;

  const lengthOfItems = Math.ceil((count || 0) / offset);

  const isMobile = useMediaQuery('(max-width: 575px)');
  const cardVariant = isMobile ? 'flexCard' : 'columnCardInfo';

  useEffect(() => {
    dispatch(getInformation({ limit: 9, offset: (page-1) * offset, }));

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ page, ]);

  return (
    <Container>
      <BreadCrumbs path={t('information')}/>
      <HTag tag="h2">{t('information')}</HTag>
      <Box sx={{ '& img, & p': { cursor: 'default !important', }, }} className={classes.informationContainer}>
        <NewsList page={null} count={12} sliceName='information' listOf='information' variant='flex' itemProps={{ variant: cardVariant, button: true, titleLength: 200, }}/>
      </Box>
      <PaginationComponent page={page} setPage={setPage} length={lengthOfItems}/>
    </Container>
  );
};

export default Information;
