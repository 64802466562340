import { createAsyncThunk, createSlice, } from '@reduxjs/toolkit';
import { REQUEST_STATUSES, } from 'common/constants';
import { addQueryCases, } from 'common/helpers';

const name = 'tests';

const ENDPOINTS = { tests: '/api/v1/tests/', categories: '/api/v1/tests/categories/', };

export const getTests = createAsyncThunk(
  `${name}/getTests`,
  async (params, { extra: api, }) => {
    const response = await api.get(`${ENDPOINTS.tests}`, { params, });
    return response.data;
  }
);

export const getCategories = createAsyncThunk(
  `${name}/getCategories`,
  async (_, { extra: api, }) => {
    const response = await api.get(ENDPOINTS.categories);
    return response.data;
  }
);

const initialState = {
  testsStatus: REQUEST_STATUSES.NOT_REQUESTED,
  testsError: null,
  tests: {},
  /**
    * tests
    * @param {string} id
    * @param {string} act_number
    * @param {string} title
    * @param {string} link
    */
  categoriesStatus: REQUEST_STATUSES.NOT_REQUESTED,
  categories: [],
  categoriesError: null,

};

const testsSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers(builder) {
    addQueryCases(builder, getTests, {
      status: 'testsStatus',
      data: 'tests',
      error: 'testsError',
      options: { concat: false, },
    });
    addQueryCases(builder, getCategories, {
      status: 'categoriesStatus',
      data: 'categories',
      error: 'categoriesError',
    });
  },
});

export default testsSlice;
