import React from 'react';
import SelectItem from '../SelectItem/SelectItem';
import { Box, } from '@mui/material';

// icons
import russianIcon from 'assets/icons/Russian.svg';
import kyrIcon from 'assets/icons/Kyrgyz.svg';
import englishIcon from 'assets/icons/English.svg';

import classes from '../../header.module.scss';

const LanguageMenu = () => {
  const langArr =
  [
    { id: 'ky', title: 'Кыр', img: kyrIcon, },
    { id: 'ru', title: 'Рус', img: russianIcon, },
    { id: 'en', title: 'Eng', img: englishIcon, },
  ];

  const lang = localStorage.getItem('lang') || 'ky';
  const selectedLang = langArr.find(item => item.id === lang);

  return (
    <Box className={classes.burgerMenu}>
      <SelectItem lang={selectedLang.title} menuContent={langArr}/>
    </Box>
  );
};

export default LanguageMenu;
