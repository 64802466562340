// hooks
import { useState, useEffect, useRef, } from 'react';
import { useDispatch, } from 'react-redux';
import { Box, Button, } from '@mui/material';
import { FormControl, InputAdornment, TextField, } from '@mui/material';
import Search from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import { useMediaQuery, } from '@mui/material';
import theme from 'theme';
import classes from './search.module.scss';
// components imports

// requests
import { setSearchValueToUse, } from 'redux-api/slices/searchSlice';
import { useNavigate, } from 'react-router-dom';
import { useTranslation, } from 'react-i18next';

const SearchItem = () => {
  const isMobile = useMediaQuery('(max-width: 768px)');
  const [ activeSearch, setActiveSearch, ] = useState(false);
  const ref = useRef(null);

  const { t, } = useTranslation();

  const display = isMobile ? 'flex' : 'none';
  const [ forms, setForm, ] = useState(isMobile ? 'none' : 'block');
  const [ icons, setIcon, ] = useState('flex');

  async function clickBtn () {
    setForm('block');
    await setIcon('none');
    ref.current.querySelector('input').focus();
  }

  // hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [ searchValue, setSearchValue, ] = useState('');

  // handlers
  const handleOnChange = (e) => {
    setSearchValue(prev => e.target.value);
  };

  const handleClick = () => {
    setSearchValue('');
    setActiveSearch(false);
    setForm('none');
    setIcon('flex');
  };

  const handleOnSubmitSearch = (e) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(setSearchValueToUse(searchValue));
    localStorage.setItem('search', JSON.stringify(searchValue));
    navigate('/searchResult');
    setActiveSearch(false);
    ref.current.querySelector('input').blur();
  };

  const onOpen = () => {
    setActiveSearch(true);
  };

  useEffect(() => {
    const onClick = e => ref.current.contains(e.target) || setActiveSearch(false);
    document.addEventListener('click', onClick);
    return () => document.removeEventListener('click', onClick);
  }, []);

  return (
    <>
      <Box
        component={'form'}
        onSubmit={(e) => { handleOnSubmitSearch(e); }}
        sx={{
          display: `${forms}`,
          [ theme.breakpoints.down('sx') ]: { width: 'auto', },
          '& .MuiFormControl-root': {
            width: '130px',
            height: '43px',
            [ theme.breakpoints.down('sx') ]: { width: 'auto', height: '20px', },
          },
        }}>
        <FormControl onChange={handleOnChange} sx={{ width: '100%', height: '100%', }} >
          <TextField
            onFocus={onOpen}
            value={searchValue}
            ref={ref}
            size="small"
            variant="outlined"
            placeholder={t('search')}
            sx={{
              height: '100%',
              [ theme.breakpoints.up('sx') ]: {
                height: '43px',
                width: '120px',
                '&:focus-within': { width: '930px', position: 'absolute', right: '10px', background: '#fff', },
              },
              [ theme.breakpoints.down('lg') ]: { '&:focus-within': { width: 'calc(82vw - 130px)', }, },
              [ theme.breakpoints.down('sx') ]: { height: '28px', },
              '& .MuiInputBase-input': {
                padding: theme.spacing(1, 1, 1, 0),
                transition: theme.transitions.create('width'),
                width: '100%',
                [ theme.breakpoints.up('sx') ]: { '&:focus': { }, },
                [ theme.breakpoints.down('sx') ]: {
                  paddingLeft: '0 !important',
                  '&:focus': { width: '19ch', },
                },
              },
              '& .MuiInputBase-root': { height: '100%', },
              '& .MuiOutlinedInput-notchedOutline': {
                [ theme.breakpoints.down('sx') ]: { borderColor: 'white !important', },
                [ theme.breakpoints.up('sx') ]: { borderColor: '#DDDBDA !important', borderWidth: '1px !important', },
              },
              '& .MuiInputAdornment-root': { color: 'black', display: icons, },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
              endAdornment: (
                <Box>
                  {isMobile ? (
                    <Box>
                      <Box>
                        <InputAdornment
                          position="end"
                          style={{ display: `${display}`, }}
                        >
                          <button
                            type='button'
                            onClick={ handleClick }
                            className={classes.clearBtn} >
                            <ClearIcon />
                          </button>
                        </InputAdornment>
                      </Box>
                    </Box>
                  ) : (
                    <InputAdornment>
                      {activeSearch && (
                        <button className={classes.searchBtn} onClick={(e) => handleOnSubmitSearch(e)}>
                          {t('find')}
                        </button>
                      )}
                    </InputAdornment>
                  )}
                </Box>
              ),
            }}
          />
          <hr className={classes.formHr}></hr>
        </FormControl>
      </Box>

      {isMobile ? (
        <Button sx={{ minWidth: '0', color: '#000000 !important', padding: '6px 0', display: `${icons}`, }} onClick={clickBtn} >
          <Search />
        </Button>
      ) : (
        <></>
      )}
    </>
  );
};

export default SearchItem;
