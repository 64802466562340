import { Typography, } from '@mui/material';
import { Rating, } from 'components/UI/Rating';
import { useTranslation, } from 'react-i18next';
import { useSelector, } from 'react-redux';
import classes from './voteStat.module.scss';

const VoteStatistics = () => {
  const { selectedVote, } = useSelector(state => state.places);
  const { t, } = useTranslation();
  return (
    <>
      <Typography className={classes.titleStat}>{t('voteLeaving')}</Typography>
      {selectedVote.answers.map((ans, i) =>
        <Rating key={i} min={'1'} max={'100'} animate={500} value={ans.percentage} title={ans.title}/>
      )}
    </>
  );
};

export default VoteStatistics;
