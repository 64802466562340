import { createSlice, createAsyncThunk, } from '@reduxjs/toolkit';
import { addQueryCases, } from 'common/helpers';
import { REQUEST_STATUSES, } from 'common/constants';


const name = 'search';


const ENDPOINTS = { SEARCH: '/api/v1/search/', };


export const getSearch = createAsyncThunk(
  `${name}/getSearch`,
  async (params, { extra: api, }) => {
    const response = await api.get(ENDPOINTS.SEARCH, { params, });
    return response.data;
  }
);


const initialState = {
  searchValue: '',

  searchStatus: REQUEST_STATUSES.NOT_REQUESTED,
  search: {},
  searchError: null,
};


const searchSlice = createSlice({
  name,
  initialState,
  reducers: {
    setSearchValueToUse(state, { payload, }) {
      state.searchValue = payload;
    },
  },
  extraReducers(builder) {
    addQueryCases(builder, getSearch, {
      status: 'searchStatus',
      data: 'search',
      error: 'searchError',
      options: { concat: false, },
    });
  },
});

export const { setSearchValueToUse, } = searchSlice.actions;

export default searchSlice;
