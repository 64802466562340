import React from 'react';
import { SwiperSlide, Swiper, } from 'swiper/react';
import { Autoplay, } from 'swiper';
import 'swiper/css';
import { Box, } from '@mui/material';
import classes from './partners.module.scss';

import undp from 'assets/images/partners/dizajn-bez-nazvaniya-1-1 1.png';
import dpi from 'assets/images/partners/Frame 121.png';
import president from 'assets/images/partners/parthner-2-1 1.png';
import goverment from 'assets/images/partners/placeholder 1.png';
import helvetas from 'assets/images/partners/Frame 120.png';
import kenesh from 'assets/images/partners/parthner1 1.png';
import msu from 'assets/images/partners/parthner-4-1 1.png';

// Array of msu partners
const partners = [
  {
    link: 'http://dpi.kg/',
    imgUrl: dpi,
  },
  {
    link: 'https://www.undp.org/kyrgyzstan',
    imgUrl: undp,
  },
  {
    link: 'https://www.president.kg/ru',
    imgUrl: president,
  },
  {
    link: 'https://www.gov.kg/ru',
    imgUrl: goverment,
  },
  {
    link: 'https://www.helvetas.org/ru/kyrgyzstan',
    imgUrl: helvetas,
  },
  {
    link: 'http://kenesh.kg/ru',
    imgUrl: kenesh,
  },
  {
    link: 'https://soyuzmsu.kg/#',
    imgUrl: msu,
  },
];

const Partners = () => {
  return (
    <Swiper id='partners'
      className={classes.slider}
      slidesPerView={'auto'}

      // some modules for autoplay
      modules={[ Autoplay, ]}

      // some styles for margins
      breakpoints={{
        0: { spaceBetween: 10, },
        768: { spaceBetween: 30, },
      }}

      //slider slide delay
      autoplay={{ delay: 1, disableOnInteraction: false, }}

      // infinity slider
      loop={true}

      //slider transition-duration
      speed={3000}
    >
      {partners.map((partner, idx) => (
        <SwiperSlide key={idx} className={`swiper-slide ${classes.slide}`}>
          <a className={classes.partnerItem} href={partner.link}>
            <Box component='img' src={partner.imgUrl} className={classes.partnerItem}/>
          </a>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default Partners;
