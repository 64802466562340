import { Box, Typography, } from '@mui/material';

import classes from './infoBodies.module.scss';
import { useTranslation, } from 'react-i18next';

const InfoBodies = () => {

  const { t, } = useTranslation();

  const column = [
    { text: t('infoBodiesColumnZero'), },
    { text: t('infoBodiesColumnOne'), },
    { text: t('infoBodiesColumnTwo'), },
    { text: t('infoBodiesColumnThree'), },
  ];

  return (
    <Box sx={{ marginTop: '40px', }} className={classes.textWrapper}>
      <Typography fontWeight="600" fontSize="21px">{t('organAboutUs')}</Typography>
      <Box className={classes.textContent}>
        <Typography lineHeight="30px" margin="40px 0">{t('infoBodiesContentOne')}</Typography>
        <Typography lineHeight="30px"><span className={classes.title}>{t('infoBodiesContentTwoTitle')}</span>{t('infoBodiesContentTwo')}</Typography>
        {column.map((column) => (
          <Typography lineHeight="30px">{column.text}</Typography>
        ))}
        <Typography margin="40px 0"> <span className={classes.title}>{t('infoBodiesContentThreeTitle')}</span>{t('infoBodiesContentThree')}</Typography>
        <Typography><span className={classes.title}>{t('infoBodiesContentFourTitle')}</span>{t('infoBodiesContentFour')}</Typography>
      </Box>
    </Box>
  );
};

export default InfoBodies;
