// Modules
import React, { useEffect, useState, } from 'react';
// Components
import { HTag, } from 'components/HTag';
import { Container, Box, } from '@mui/material';
import Empty from 'components/Empty/Empty';
import TabsContent from 'components/UI/TabsContent/TabsContent';
import AccordionComponent from 'components/UI/Accordion/AccordionComponent';
import PaginationComponent from 'components/UI/Pagination/PaginationComponent';

import { useTranslation, } from 'react-i18next';
import { useDispatch, useSelector, } from 'react-redux';

import { getCategories, getQuestions, } from 'redux-api/slices/questionsSlice';
import { BreadCrumbs, } from 'components/BreadCrumbs';


const Questions = () => {
  const dispatch = useDispatch();
  const { t, } = useTranslation();
  const { categories: preCategories, questions, } = useSelector(state => state.questions);

  //page
  const [ page, setPage, ] = useState(1);
  const offset = 9;
  const lengthItems = Math.ceil((questions?.count || 0) / offset);


  const categories = [ { id: 'standart', title: t('allCategories'), }, ...preCategories, ];
  const [ value, setValue, ] = useState('standart');
  // const [ expanded, setExpanded, ] = useState(false);

  // const handleChange = panel => (event, isExpanded) => {
  //   setExpanded(isExpanded ? panel : false);
  // };

  useEffect(() => {
    setValue(categories?.[ 0 ]?.id);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ preCategories, ]);
  useEffect(() => {
    dispatch(getCategories());
  }, [ dispatch, ]);
  useEffect(() => {
    dispatch(getQuestions({ category: value !== 'standart' ? value : '', limit: 9, offset: (page - 1) * offset, }));
  }, [ dispatch,
    value,
    page, ]);

  return (
    <Container>
      <BreadCrumbs path={t('questions')}/>
      <Box>
        <HTag tag={'h2'}>
          {t('freqAskQues')}
        </HTag>
        <TabsContent valueKey='id' labelKey='title' tabsList={categories} value={value} setValue={setValue} />
        {
          questions?.count
            ?
            questions?.results?.map((item, id) =>
              <AccordionComponent
                key={id}
                title={item?.question}
                description={item?.answer}
                id={id}
                // handleChange={handleChange}
                // expanded={expanded}
              />)
            :
            <Empty/>
        }
        <PaginationComponent
          page={page}
          setPage={setPage}
          length={lengthItems}
        />
      </Box>
    </Container>
  );
};

export default Questions;
