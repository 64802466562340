import { Box, } from '@mui/material';
import { currentLang, } from 'common/helpers';
import Footer from 'components/Footer';
import { useEffect, } from 'react';
import { Outlet, useLocation, } from 'react-router-dom';
import { Header, } from '../Header';

const Layout = () => {
  const { pathname, } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [ pathname, ]);

  useEffect(() => {
    currentLang();
  }, []);

  return (
    <>
      <Header/>
      <Box sx={{ minHeight: 'calc(100vh - 64px)', }}>
        <Outlet/>
      </Box>
      <Footer/>
    </>
  );
};

export default Layout;
