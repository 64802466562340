import { Box, } from '@mui/material';
import classes from './stepsLength.module.scss';

const StepsLength = ({ currentIndex, length, }) => {

  const arrLen = [ ...Array(length), ];

  return (
    <Box className={classes.stepsList}>
      {arrLen.map((item, i) =>
        <Box className={classes.stepItem} key={i}>
          <Box className={currentIndex >= (i + 1) ? classes.stepActive : classes.stepNotActive}/>
        </Box>
      )}
    </Box>
  );
};

export default StepsLength;
