import { Box, } from '@mui/material';
import { useEffect, useState, } from 'react';
import { useDispatch, useSelector, } from 'react-redux';
import { useLocation, } from 'react-router-dom';
import { getDecrees, } from 'redux-api/slices/placesSlice';
import PaginationComponent from 'components/UI/Pagination/PaginationComponent';
import DecreesCard from '../DecreesCard/DecreesCard';

import classes from './decreesList.module.scss';
import { getActs, } from 'redux-api/slices/actsSlice';
import { PATHS, } from 'common/constants';
import Empty from 'components/Empty/Empty';


const GetRightList = ({ decrees, acts, actsActive, }) => {
  switch (actsActive) {
  case false:
    return (
      decrees?.map((item, i) =>
        <DecreesCard
          key={i}
          number={item.number}
          text={item.description}
          date={item.created_at}
          link={item.file}
        />
      )
    );
  case true:
    return (
      acts?.map((item, i) =>
        <DecreesCard
          key={i}
          number={item.act_number}
          text={item.title}
          link={item.link}
          acts={actsActive}
        />
      )
    );
  default: <></>;
  }
};


const DecreesList = ({ actsActive = false, }) => {
  const decrees = useSelector(state => state.places.decrees);
  const acts = useSelector(state => state.acts.acts);
  const location = useLocation();
  const [ page, setPage, ] = useState(1);
  const offset = 10;
  const lengthItems = Math.ceil(((actsActive ? acts : decrees).count || 0) / offset);
  const dispatch = useDispatch();

  useEffect(() => {
    if (actsActive) {
      dispatch(getActs({ limit: offset, offset: (page-1) * offset, }));
      return;
    }
    dispatch(getDecrees({ limit: offset, offset: (page-1) * offset, }));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ page, ]);

  return (
    <>
      <Box className={classes.wrapper}>
        <GetRightList acts={acts.results} decrees={decrees.results} actsActive={actsActive}/>
        { location.pathname === PATHS?.decrees && !(decrees?.count) && <Empty/> }
        { location.pathname === PATHS?.legislation && !(acts?.count) && <Empty/> }
      </Box>
      <PaginationComponent page={page} setPage={setPage} length={lengthItems}/>
    </>
  );
};

export default DecreesList;
