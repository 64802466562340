import React from 'react';
import { Box, Typography, useMediaQuery, } from '@mui/material';
import { useSelector, } from 'react-redux';

// importing swiper elements
import { SwiperSlide, Swiper, } from 'swiper/react';

import parse from 'html-react-parser';

import classes from './newsDescription.module.scss';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/bundle';

// import required modules
import { A11y, Navigation, Pagination, } from 'swiper';

const NewsDescription = () => {
  const { news, } = useSelector(state => state.news);
  const isMobile = useMediaQuery('(max-width: 575px)');

  return (
    <Box className='pagin'>
      <Swiper
        navigation={true}
        centeredSlides={true}
        pagination={true}
        modules={[ Pagination,
          Navigation,
          A11y, ]}
        loop
        style={{
          '--swiper-navigation-color': '#FFFFFF',
          '--swiper-navigation-size': isMobile ? '24px' : '44px',
          '.swiper-pagination': { position: 'unset', },
        }}
      >
        {news?.news_detail_images?.map((item, idx) => (
          <SwiperSlide>
            <Box component='img' className={classes.bigImg} src={item?.image}/>
            <Box className={classes.title}>
              <Typography className={classes.titleMore}>
                {news?.title}
              </Typography>
            </Box>
          </SwiperSlide>
        ))}
      </Swiper>
      <Box className={classes.description}>
        {news?.description && parse(news?.description)}
      </Box>
    </Box>
  );
};

export default NewsDescription;
