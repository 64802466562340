import { createTheme, } from '@mui/material';

export const theme = createTheme({
  palette: { primary: { main: '#000000', }, },
  breakpoints: {
    values: {
      lg: 1200,
      sm: 820,
      mb: 520,
      xs: 0,
      sx: 768,
    },
  },
  typography: { fontFamily: [ 'Montserrat', ].join(';'), },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        fontFamily: 'Montserrat',
      `,
    },
    MuiContainer: {
      styleOverrides: {
        root: ({ theme, }) => ({
          maxWidth: '1200px !important',
          [ theme.breakpoints.up('lg') ]: { padding: '0 !important', },
        }),
      },
    },
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState, }) => ({
          borderRadius: 0,
          ...(ownerState.variant === 'contained' &&
            ownerState.color === 'primary' && {
            backgroundColor: '',
            color: '#ffff',
            '&:hover': { backgroundColor: '#0000', },
          }
          ),
          ...(ownerState.variant === 'text' &&
            ownerState.color === 'primary' && {
            color: '#014782',

            '&:hover': { color: '#0286F2', },
          }),
        }),
      },
    },
  },

});


theme.typography.h1 = {
  ...theme.typography.h1,
  fontWeight: '600',
  fontSize: 18,
  color: '#000000',
  lineHeight: '20px',
  [ theme.breakpoints.up ]: {
    fontSize: 28,
    lineHeight: '35px',
  },
};


export default theme;
