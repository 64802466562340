import { createAsyncThunk, createSlice, } from '@reduxjs/toolkit';
import { REQUEST_STATUSES, } from 'common/constants';
import { addQueryCases, } from 'common/helpers';

const name = 'questions';

const ENDPOINTS = {
  QUESTIONS: '/api/v1/faq/questions/',
  CATEGORIES: '/api/v1/faq/categories/',
};

export const getQuestions = createAsyncThunk(
  `${name}/getQuestions`,
  async (params, { extra: api, }) => {
    const response = await api.get(ENDPOINTS.QUESTIONS, { params, });
    return { results: response.data, };
  }
);

export const getCategories = createAsyncThunk(
  `${name}/getCategories`,
  async (_, { extra: api, }) => {
    const response = await api.get(ENDPOINTS.CATEGORIES);
    return response.data;
  }
);

const initialState = {
  questionsStatus: REQUEST_STATUSES.NOT_REQUESTED,
  questions: [],
  questionsError: null,

  categoriesStatus: REQUEST_STATUSES.NOT_REQUESTED,
  categories: [],
  categoriesError: null,

};

const questionsSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers(builder) {
    addQueryCases(builder, getQuestions, {
      status: 'questionsStatus',
      data: 'questions',
      error: 'questionsError',
    });
    addQueryCases(builder, getCategories, {
      status: 'categoriesStatus',
      data: 'categories',
      error: 'categoriesError',
    });
  },
});

export default questionsSlice;
