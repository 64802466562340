import { FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, } from '@mui/material';
import { SkeletonCard, } from 'components/SkeletonCard';
import { useTranslation, } from 'react-i18next';

import classes from './yesNot.module.scss';

import './yesNot.scss';

const YesNot = ({ statusAnswer, title, asnwersList, answer, setAnswer, }) => {


  const { t, } = useTranslation();
  const handleChange = (event) => {
    setAnswer(event.target.value);
  };

  if (statusAnswer === 'requested') {
    return [ ...Array(6), ].map((i) => <SkeletonCard key={i} variant='text' width='100%' height='60px'/>);
  }

  return (
    <FormControl className={classes.radioWrapper}>
      <FormLabel
        id="demo-controlled-radio-buttons-group"
        className={classes.radioTitle}
      >{t('fillVote')}</FormLabel>
      <RadioGroup
        aria-labelledby="demo-controlled-radio-buttons-group"
        name="controlled-radio-buttons-group"
        value={answer}
        onChange={handleChange}
        className={classes.radioList}
      >
        {asnwersList?.map((ans) =>
          <FormControlLabel
            key={ans.id}
            className={classes.radioItem}
            value={ans.id}
            control={<Radio />}
            label={ans.title}
            sx={{
              color: ans.id === answer ? '#fff' : '#000',
              background: ans.id === answer ? '#2F80ED' : 'transperent',
              position: 'relative',
              transition: 'all 0.3s ease',
              '& .css-hyxlzm': {
                width: '20px',
                height: '20px',
                border: ans.id === answer ? 'none' : '2px solid black',
                '&::after': { display: ans.id === answer ? 'block' : 'none', },
              },
            }} />
        )}
      </RadioGroup>
    </FormControl>
  );
};

export default YesNot;
