// components
import { Box, Paper, Typography, } from '@mui/material';
import { YesNot, } from 'components/YesNot';
import { StepsLength, } from 'components/StepsLength';
import { VoteForm, } from 'components/VoteForm';
import { VoteStatistics, } from 'components/VoteStatistics';

// hooks
import { useState, } from 'react';
import { useDispatch, useSelector, } from 'react-redux';
import { useNavigate, useParams, } from 'react-router-dom';

// requests
import { getVoteId, postVoteCreate, } from 'redux-api/slices/placesSlice';

import { toast, ToastContainer, } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import classes from './voteSteps.module.scss';
import { CustomButton, } from 'components/UI/CustomButton';
import axios from 'axios';
import { useTranslation, } from 'react-i18next';

const StepNumber = ({ number, dataVote, statusAnswer, handleOnChangeVote, title, asnwersList, answer, setAnswer, }) => {
  switch (number) {
  case 1: {
    return <YesNot statusAnswer={statusAnswer} answer={answer} setAnswer={setAnswer} title={title} asnwersList={asnwersList}/>;
  }
  case 2: {
    return <VoteForm dataVote={dataVote} handleOnChangeVote={handleOnChangeVote}/>;
  }
  case 3: {
    return <VoteStatistics/>;
  }
  default:
    return <></>;
  }
};

const StepBtn = ({ number, PostCreateVote, handlePrevStep, handleNextStep, handleNavigate, }) => {
  const { t, } = useTranslation();
  switch (number) {
  case 1: {
    return (
      <Box className={classes.btnWrapper}>
        <CustomButton handleDo={handleNextStep} active>{t('votes')}</CustomButton>
      </Box>
    );
  }
  case 2: {
    return (
      <Box className={classes.btnWrapperBetween}>
        <CustomButton handleDo={handlePrevStep}>{t('back')}</CustomButton>
        <CustomButton handleDo={PostCreateVote} active>{t('further')}</CustomButton>
      </Box>
    );
  }
  case 3: {
    return (
      <Box className={classes.btnWrapper}>
        <CustomButton handleDo={handleNavigate} active>{t('ready')}</CustomButton>
      </Box>
    );
  }
  default:
    return <></>;
  }
};

const VoteSteps = () => {
  // INITIAL -------------------

  const [ currentIndex, setCurrentIndex, ] = useState(1);
  const [ dataVote, setDataVote, ] = useState({
    fullName: '',
    gender: '',
    region: '',
    district: '',
    rural: '',
    village: '',
  });
  const [ answer, setAnswer, ] = useState('');
  const { regionsCityList, selectedVote, selectedVoteStatus, } = useSelector(state => state.places);
  const { id, } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, } = useTranslation();


  // REQUESTS --------------------

  const PostCreateVote = async () => {

    axios.defaults.xsrfCookieName = 'csrftoken';
    axios.defaults.xsrfHeaderName = 'X-CSRFToken';

    const city = regionsCityList?.find((item) => item.id === dataVote.region);

    const toasting = toast.error;
    const toastSuccess = toast.success;

    if (!dataVote.gender || !dataVote.region) {
      return toasting(`${t('voteError')}\n${t('voteErrorSecond')}`, { position: toast.POSITION.TOP_RIGHT, });
    }

    if (!city && (!dataVote.district || !dataVote.rural || !dataVote.village)) {
      return toasting(`${t('voteError')}\n${t('voteErrorSecond')}`, { position: toast.POSITION.TOP_RIGHT, });
    }

    toastSuccess(t('voteSuccess'), { position: toast.POSITION.TOP_RIGHT, });

    const params = {
      full_name: dataVote.fullName,
      sex: dataVote.gender,
      zone: dataVote.rural || null,
      city_department: city?.id || null,
      answer: [ answer, ],
      village: dataVote.village,
    };

    try {
      await dispatch(postVoteCreate(params));
      await dispatch(getVoteId(id));
      handleNextStep();
    } catch (error) {
      alert('Something went wrong');
    }
  };

  // HANDLERS --------------

  const handleNextStep = () => {
    if (answer) setCurrentIndex(prev => prev + 1);
  };
  const handlePrevStep = () => {
    setCurrentIndex(prev => prev - 1);
  };
  const handleNavigate = () => {
    navigate('/vote');
  };
  const handleOnChangeVote = (e) => {
    setDataVote({ ...dataVote, [ e.target.name ]: e.target.value, });
  };

  return (
    <Box className={classes.flexCenter}>
      <Paper className={classes.paperWrapper}>
        <Typography className={classes.title}>{selectedVote?.title}
        </Typography>
        <StepNumber number={currentIndex} dataVote={dataVote} handleOnChangeVote={handleOnChangeVote} statusAnswer={selectedVoteStatus} asnwersList={selectedVote.answers} title={selectedVote.description} answer={answer} setAnswer={setAnswer}/>
        <StepsLength currentIndex={currentIndex} length={3}/>
        <StepBtn number={currentIndex} handleNavigate={handleNavigate} handleNextStep={handleNextStep} handlePrevStep={handlePrevStep} PostCreateVote={PostCreateVote}/>
        <ToastContainer />
      </Paper>
    </Box>
  );
};

export default VoteSteps;
