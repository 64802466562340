import React from 'react';
import { Container, Box, Typography, Button, } from '@mui/material';
import { useTranslation, } from 'react-i18next';
import classes from './test.module.scss';
import passTestImg from 'assets/images/testImg.png';
import { useNavigate, } from 'react-router-dom';


const Test = () => {

  const { t, } = useTranslation();
  const description = t('mainTest');
  const titleLength = 200;
  const navigate = useNavigate();
  const slicedDescription = description.length >= titleLength ? description.slice(0, titleLength) + '...' : description;

  const handleClick = () => {
    navigate('/tests');
  };

  return (
    <Box className={classes.test}>
      <Container className={classes.container}>
        <Box component='img' src={passTestImg} className={classes.testImg}/>
        <Box className={classes.textContent}>
          <Typography className={classes.description}>
            {slicedDescription}
          </Typography>
          <Button onClick={handleClick} className={classes.testBtn}>{t('passTest')}</Button>
        </Box>
      </Container>
    </Box>
  );
};

export default Test;
