import { NewsList, } from 'components/NewsList';
import React from 'react';
import { Box, Container, Typography, } from '@mui/material';
import { NewsForm, } from 'components/NewsForm';
import { useMediaQuery, } from '@mui/material';
import { NewsPagination, } from 'components/NewsPagination';
import { BreadCrumbs, } from 'components/BreadCrumbs';
import { useTranslation, } from 'react-i18next';
import { Element, } from 'react-scroll';

const News = () => {
  const isMobile = useMediaQuery('(max-width: 575px)');
  const newsCardLimit = isMobile ? false : true;
  const newsListLimit = isMobile ? 5 : 8;
  const { t, } = useTranslation();

  return (
    <Box>
      <Container>
        <BreadCrumbs path={t('news')} />
        <Element name='news'></Element>
        <Typography sx={{ fontWeight: 600, margin: '30px 0', fontSize: '25px', }}>
          {t('lastNews')}
        </Typography>
        <NewsForm/>
        <Typography component='h1' sx={{ fontWeight: '600', fontSize: '22px', marginTop: '64px', marginBottom: '40px', }}>
          {t('allNews')}
        </Typography>
        <NewsList count={newsListLimit} listOf='newsList' itemDivider={newsCardLimit} itemProps={{ variant: 'mainNewsCard', button: false, titleLength: 400, }} variant='flex'/>
        <NewsPagination scrollTo='news' />
      </Container>
    </Box>
  );
};

export default News;
