import React, { useEffect, useState, } from 'react';

// import styles from scss
import classes from './vacancy.module.scss';
/// MUI components;
import { Box, Container, } from '@mui/material';
/// import custom components;
import { HTag, } from 'components/HTag';
import AccordionComponent from 'components/UI/Accordion/AccordionComponent';
import Empty from 'components/Empty/Empty';
// import fetch data functions;
import { getVacancies, } from 'redux-api/slices/vacanciesSlice';
import { useSelector, } from 'react-redux';
import { useDispatch, } from 'react-redux';
import { useTranslation, } from 'react-i18next';
import PaginationComponent from 'components/UI/Pagination/PaginationComponent';

const Vacancy = () => {
  const { t, } = useTranslation();
  const dispatch = useDispatch();
  const { vacancies: fullVacancies, } = useSelector(state => state?.vacancies);
  const vacancies = fullVacancies?.results;

  const [ expanded, setExpanded, ] = useState(false);
  const [ page, setPage, ] = useState(1);

  const offset = 9;
  const lengthItems = Math.ceil((fullVacancies?.count || 0) / offset);

  const handleChange = (panel) => (_, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  useEffect(() => {
    dispatch(getVacancies({ limit: 9, offset: (page - 1) * offset, }));
  }, [ dispatch, page, ]);
  return <>
    <Box className={classes.mainVacancy}>
      <Container>
        <HTag tag={'h2'}>
          {t('vacancy')}
        </HTag>
        <Box sx={{ maxWidth: '1135px', margin: '0 auto', }}>
          {
            fullVacancies?.count
              ?
              vacancies?.map((acc) => <AccordionComponent title={acc.title} description={acc.description} key={acc.id} id={acc.id} handleChange={handleChange} expanded={expanded} />)
              :
              <Empty/>
          }
          <PaginationComponent
            page={page}
            setPage={setPage}
            length={lengthItems}
          />
        </Box>
      </Container>
    </Box>
  </>;
};

export default Vacancy;
