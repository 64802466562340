import { REQUEST_STATUSES, } from 'common/constants';
import { addQueryCases, } from 'common/helpers';
import { createAsyncThunk, createSlice, } from '@reduxjs/toolkit';

const name = 'places';

const ENDPOINTS = { RESOLUTIONS: '/api/v1/resolutions/', VOTES: '/api/v1/votes/', };
ENDPOINTS.districts = `${ENDPOINTS.RESOLUTIONS}districts/`;
ENDPOINTS.region_city = `${ENDPOINTS.RESOLUTIONS}region_city/`;
ENDPOINTS.regions = `${ENDPOINTS.RESOLUTIONS}regions/`;
ENDPOINTS.rurals = `${ENDPOINTS.RESOLUTIONS}rurals/`;

ENDPOINTS.votesList = `${ENDPOINTS.VOTES}`;
ENDPOINTS.districtsList = `${ENDPOINTS.VOTES}districts/`;
ENDPOINTS.regionsList = `${ENDPOINTS.VOTES}regions/`;
ENDPOINTS.regionsCityList = `${ENDPOINTS.VOTES}region_city/`;
ENDPOINTS.ruralsList = `${ENDPOINTS.VOTES}rurals/`;
ENDPOINTS.voteCreate = `${ENDPOINTS.VOTES}user/create/`;
ENDPOINTS.villagesList = `${ENDPOINTS.VOTES}management/`;


export const getDecrees = createAsyncThunk(
  `${name}/getDecrees`,
  async(params, { extra: api, }) => {
    const res = await api.get(ENDPOINTS.RESOLUTIONS, { params, });
    return res.data;
  }
);
export const getRegion = createAsyncThunk(
  `${name}/getRegion`,
  async(_, { extra: api, }) => {
    const res = await api.get(ENDPOINTS.regions);
    return { results: res.data, };
  }
);

export const getVillagesList = createAsyncThunk(
  `${name}/getVillagesList`,
  async(id, { extra: api, }) => {
    const res = await api.get(`${ENDPOINTS.villagesList}?id=${id}`);
    return { results: res.data, };
  }
);

export const getRegionCity = createAsyncThunk(
  `${name}/getRegionCity`,
  async(_, { extra: api, }) => {
    const res = await api.get(ENDPOINTS.region_city);
    return { results: res.data, };
  }
);

export const getDistricts = createAsyncThunk(
  `${name}/getDistricts`,
  async(id, { extra: api, }) => {
    const res = await api.get(`${ENDPOINTS.districts}?region=${id}`);
    return { results: res.data, };
  }
);

export const getRurals = createAsyncThunk(
  `${name}/getRurals`,
  async(id, { extra: api, }) => {
    const res = await api.get(`${ENDPOINTS.rurals}?district=${id}`);
    return { results: res.data, };
  }
);

export const getDistrictsList = createAsyncThunk(
  `${name}/getDistrictsList`,
  async (regionId, { extra: api, }) => {
    const res = await api.get(`${ENDPOINTS.districtsList}?region=${regionId}`);
    return res.data;
  }
);
export const getRegionsList = createAsyncThunk(
  `${name}/getRegionsList`,
  async (_, { extra: api, }) => {
    const res = await api.get(`${ENDPOINTS.regionsList}`);
    return res.data;
  }
);
export const getRegionsCityList = createAsyncThunk(
  `${name}/getRegionsCityList`,
  async (_, { extra: api, }) => {
    const res = await api.get(`${ENDPOINTS.regionsCityList}`);
    return res.data;
  }
);
export const getRuralsList = createAsyncThunk(
  `${name}/getRuralsList`,
  async (districtId, { extra: api, }) => {
    const res = await api.get(`${ENDPOINTS.ruralsList}?district=${districtId}`);
    return res.data;
  }
);
export const getVotesList = createAsyncThunk(
  `${name}/getVotesList`,
  async (params = { limit: 100, offset: 0, }, { extra: api, }) => {

    const res = await api.get(ENDPOINTS.votesList, { params, });
    return res.data;
  }
);
export const getVoteId = createAsyncThunk(
  `${name}/getVoteId`,
  async (id, { extra: api, }) => {
    const res = await api.get(`${ENDPOINTS.VOTES}${id}`);
    return res.data;
  }
);

// POST REQUESTS ----------------------

export const postVoteCreate = createAsyncThunk(
  `${name}/postVoteCreate`,
  async (params, { extra: api, }) => {
    try {
      const res = await api.post(`${ENDPOINTS.voteCreate}`, params);
      return res.data;
    } catch (error) {
      throw error;
    }
  }
);

const initialState = {
  decreesStatus: REQUEST_STATUSES.NOT_REQUESTED,
  decrees: [],
  districts: [],
  regionCity: [],
  regions: [],
  villages: [],
  districtsList: [],
  regionsList: [],
  regionsCityList: [],
  ruralsList: [],

  villagesList: [],
  votesList: [],
  votesListStatus: '',

  selectedVote: {},
  selectedVoteStatus: '',

  voteCreate: {},
  decreesError: null,
};

const decreesSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers(builder) {
    addQueryCases(builder, getDecrees, {
      status: 'decreesStatus',
      data: 'decrees',
      error: 'decreesError',
      options: { concat: false, },
    });
    addQueryCases(builder, getDistricts, {
      status: '',
      data: 'districts',
      error: '',
    });
    addQueryCases(builder, getRegionCity, {
      status: '',
      data: 'regionCity',
      error: '',
    });
    addQueryCases(builder, getRegion, {
      status: '',
      data: 'regions',
      error: '',
    });
    addQueryCases(builder, getRurals, {
      status: '',
      data: 'villages',
      error: '',
    });
    addQueryCases(builder, getVillagesList, {
      status: '',
      data: 'villagesList',
      error: '',
    });
    addQueryCases(builder, getDistrictsList, {
      status: '',
      data: 'districtsList',
      error: '',
    });
    addQueryCases(builder, getRegionsList, {
      status: '',
      data: 'regionsList',
      error: '',
    });
    addQueryCases(builder, getRegionsCityList, {
      status: '',
      data: 'regionsCityList',
      error: '',
    });
    addQueryCases(builder, getRuralsList, {
      status: '',
      data: 'ruralsList',
      error: '',
    });
    addQueryCases(builder, getVotesList, {
      status: 'votesListStatus',
      data: 'votesList',
      error: '',
      options: { concat: false, },
    });
    addQueryCases(builder, getVoteId, {
      status: 'selectedVoteStatus',
      data: 'selectedVote',
      error: '',
      options: { concat: false, },
    });

    // POST REQUESTS --------------------

    addQueryCases(builder, postVoteCreate, {
      status: '',
      data: 'voteCreate',
      error: '',
      options: { concat: false, },
    });
  },
});

export default decreesSlice;
