import { CircularProgress, Box, Container, } from '@mui/material';
import { useEffect, useState, } from 'react';
import { useDispatch, useSelector, } from 'react-redux';
import { useNavigate, useParams, } from 'react-router-dom';
import { getStatistic, } from 'redux-api/slices/statisticSlice';
import { TabsContent, } from 'components/UI/TabsContent';
import classes from './statistic.module.scss';
import Chart from 'react-apexcharts';

import html2PDF from 'jspdf-html2canvas';


const Diagramm = ({ female, male, }) => {

  const options = { labels: [ `Мужчины: ${male}`, `Женщины: ${female}`, ], chart: { width: 200, animations: { enabled: false, }, }, legend: { right: 80, position: 'bottom', }, tooltip: { enabled: false, }, };

  const series = [ male, female, ];
  return (
    <>
      <Container>
        <Chart
          options={options}
          series={series}
          type="pie"
          width={400}
          height={350}
        />
      </Container>
    </>
  );
};


const DiagrammReg = ({ dataArray, }) => {

  const data = ArrayKey(dataArray);

  const options = {
    labels: [],
    chart: {
      pie: { size: 200, },
      animations: { enabled: false, },
    },
    legend: { position: 'bottom', },
    tooltip: { enabled: false, },
  };

  const series = [];

  data.forEach((element) => {
    series.push(element.count);
    options.labels.push(`${element.title}:${element.count}`);

  });

  return (
    <>
      <Chart
        options={options}
        series={series}
        type="pie"
        width={300}
        height={1000}
      />
    </>
  );
};


const ArrayKey = (data, key) => {
  const results = [];
  Object.entries(data).forEach(([ title, count, ]) => {
    results.push({ title, count, });
  });
  return results;
};

const Statistic = () => {
  const dispatch = useDispatch();
  const { id, } = useParams();
  const { statistic, } = useSelector(state => state.statistic);
  const navigate = useNavigate();

  /* LOADER */
  const loading = false;

  /* ANSWERS */
  const [ answersList, setAnswersList, ] = useState({});

  useEffect(() => {
    dispatch(getStatistic(id))
      .catch((e) => {
        navigate('/');
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ dispatch, id, ]);

  useEffect(() => {
    if (Object.keys(statistic).length === 0) {
      return;
    }
    /* ANSWERES */
    const answers = statistic.answers;

    answers?.forEach((answer) => {
      setAnswersList(prev => {
        return { ...prev, [ answer.title ]: answer, };
      });
    });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ statistic, ]);

  const page = document.querySelector('.downloadDiagramWrapper');

  const generatePDF = () => {
    html2PDF(page, {
      jsPDF: { format: 'a4', },
      imageType: 'image/jpeg',
      output: 'statistic.pdf',
    });
  };

  const VALUES = { souz: 'souz', history: 'history', organ: 'organ', projects: 'projects', ourTeam: 'our-team', };

  const tabsList = [
    { value: VALUES.souz, label: 'Кыргызстан', },
    { value: VALUES.history, label: 'Области', },
    { value: VALUES.organ, label: 'Города', },
    { value: VALUES.projects, label: 'Районы', },
    { value: VALUES.ourTeam, label: 'Айыл', },
  ];

  const [ value, setValue, ] = useState(tabsList?.[ 0 ]?.value);


  const Digr = () => {
    switch (value) {
    case VALUES.souz: {
      return (
        Object.keys(answersList).map((key, i) =>
          <Container>
            <Box className={classes.main}>
              <h3 className={classes.desc}>{key}</h3>
              <Diagramm title={key} dataArray={answersList[ key ]?.regions_count} female={answersList[ key ]?.female_count} male={answersList[ key ]?.male_count} />
            </Box>
          </Container>
        )

      );
    }
    case VALUES.history: {
      return Object.keys(answersList).map((key, i) =>
        <Container>
          <Box className={classes.main}>
            <h3 className={classes.desc}>{key}</h3>
            <DiagrammReg title={key} dataArray={answersList[ key ]?.regions_count} />
          </Box>
        </Container>

      );
    }
    case VALUES.organ: {
      return Object.keys(answersList).map((key, i) =>
        <Container>
          <Box className={classes.main}>
            <h3 className={classes.desc}>{key}</h3>
            <DiagrammReg title={key} dataArray={answersList[ key ]?.citydepartment_count} />
          </Box>
        </Container>
      );
    }
    case VALUES.projects: {
      return Object.keys(answersList).map((key, i) =>
        <Container>
          <Box className={classes.main}>
            <h3 className={classes.desc}>{key}</h3>
            <DiagrammReg title={key} dataArray={answersList[ key ]?.district_count} />
          </Box>
        </Container>
      );
    }
    case VALUES.ourTeam: {
      return Object.keys(answersList).map((key, i) =>
        <Container>
          <Box className={classes.main}>
            <h3 className={classes.desc}>{key}</h3>
            <DiagrammReg title={key} dataArray={answersList[ key ]?.ruralmanagement_count} />
          </Box>
        </Container>
      );
    }
    default:
      return <></>;
    }
  };

  return (
    <Container>
      <div className={classes.pageWrapper}>
        <div className={classes.container}>
          <div className='diagrammWrapper'>
            <div className='downloadDiagramWrapper'>
              <h2 className={classes.answerTitle}>Вопрос: {statistic.title}</h2>
              <h4 className={classes.answerDescription}>Описание: {statistic.description}</h4>
              <TabsContent tabsList={tabsList} value={value} setValue={setValue} />
              <div className={classes.flexWrapper }>
                <Digr />
              </div>
            </div>
          </div>
          <button className={classes.btnToDownload} onClick={generatePDF}>
            Скачать в PDF
            { loading && (
              <CircularProgress color='secondary' style={{ color: '#fff', width: '25px', height: '25px', }} />
            )}
          </button>
        </div>
      </div>
    </Container>
  );
};

export default Statistic;
