// components
import { Container, } from '@mui/material';
import { BreadCrumbs, } from 'components/BreadCrumbs';
import Empty from 'components/Empty/Empty';
import { HTag, } from 'components/HTag';
import PaginationComponent from 'components/UI/Pagination/PaginationComponent';
import { VoteList, } from 'components/VoteList';

// hooks
import { useEffect, useState, } from 'react';
import { useTranslation, } from 'react-i18next';
import { useDispatch, useSelector, } from 'react-redux';
import { getVotesList, } from 'redux-api/slices/placesSlice';

const Vote = () => {
  const { t, } = useTranslation();
  const { votesList, votesListStatus, } = useSelector(state => state.places);
  const dispatch = useDispatch();
  const [ page, setPage, ] = useState(1);
  const offset = 9;
  const lengthItems = Math.ceil((votesList.count || 0) / offset);

  useEffect(() => {
    dispatch(getVotesList({ limit: 9, offset: (page - 1) * offset, }));
  }, [ dispatch, page, ]);

  return (
    <Container>
      <BreadCrumbs path={t('votes')}/>
      <HTag tag={'h2'}>
        {t('votes')}
      </HTag>
      <VoteList list={votesList.results} status={votesListStatus} mainColor='#0770B8' btnHover='#50B227'/>
      { !votesList?.count && <Empty/> }
      <PaginationComponent
        page={page}
        setPage={setPage}
        length={lengthItems}
      />
    </Container>
  );
};

export default Vote;
