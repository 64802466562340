// Modules
import React from 'react';
// Components
import { Typography, } from '@mui/material';
import { useTranslation, } from 'react-i18next';
// Style
import classes from './Empty.module.scss';

const Empty = () => {
  const { t, } = useTranslation();

  return <div className={classes.empty}><Typography className={classes.text}>{t('empty')}</Typography></div>;
};

export default Empty;
