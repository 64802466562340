import { NewsList, } from 'components/NewsList';

const Information = () => {
  return (
    <NewsList
      page={null}
      variant='mainInfo'
      sliceName='information'
      listOf='information'
      itemProps={{ variant: 'mainInfoCard', button: true, }}
      count={4}
    />
  );
};

export default Information;
