import { createAsyncThunk, createSlice, } from '@reduxjs/toolkit';
import { REQUEST_STATUSES, } from 'common/constants';
import { addQueryCases, } from 'common/helpers';

const name = 'statistic';

const ENDPOINTS = { statistic: '/api/v1/votes/statistic/', };

export const getStatistic = createAsyncThunk(
  `${name}/getStatistic`,
  async (id, { extra: api, }) => {
    const response = await api.get(`${ENDPOINTS.statistic}${id}`);
    return response.data;
  }
);

const initialState = {
  statisticStatus: REQUEST_STATUSES.NOT_REQUESTED,
  statisticError: null,
  statistic: {},
  /**
    * statistic
    * @param {string} id
    * @param {string} act_number
    * @param {string} title
    * @param {string} link
    */
};

const statisticSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers(builder) {
    addQueryCases(builder, getStatistic, {
      status: 'statisticStatus',
      data: 'statistic',
      error: 'statisticError',
      options: { concat: false, },
    });
  },
});

export default statisticSlice;
