// components
import { Box, Typography, useMediaQuery, } from '@mui/material';

// hooks
import { useEffect, useState, } from 'react';

import classes from './rating.module.scss';

// BY PERCENT

const Rating = ({ value, title, animate, }) => {
  const [ timeValue, setTimeValue, ] = useState(0);
  const isMobile = useMediaQuery('(max-width: 768px)');
  const slice = isMobile ? `${title.slice(0, 20)}...` : title;

  useEffect(() => {
    setTimeout(() => {
      setTimeValue(prev => value);
    }, animate);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ value, ]);

  return (
    <Box className={classes.inputWrapper}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', }}>
        <Typography className={classes.titleRange}>{slice}</Typography>
        <Typography className={classes.titleRangeValue}>{value + '%'}</Typography>
      </Box>
      <div
        className={classes.inputRange}
        style={{
          background:
        `-webkit-linear-gradient(left, #2196F3 0%, #2196F3 ${100}%, #fff 0%, #fff 25%, #fff 100%)`,
          width: `${timeValue}%`,
        }}
      />
    </Box>
  );
};

export default Rating;
