// components
import { Box, Button, Popover, } from '@mui/material';
import NavigationLink from '../Navigation/Link';

// hooks
import { useEffect, useState, } from 'react';

// icons
import russianIcon from 'assets/icons/Russian.svg';
import kyrIcon from 'assets/icons/Kyrgyz.svg';
import englishIcon from 'assets/icons/English.svg';

import i18n from 'i18n';
import classes from './burger.module.scss';
import { changeLang, } from 'common/helpers';
import { useLocation, } from 'react-router-dom';

const Burger = ({ show, }) => {
  return (
    <Box className={classes.bgParent}>
      <Box className={show ? classes.bgItemActive : classes.bgItem}/>
      <Box className={show ? classes.bgItemActive : classes.bgItem}/>
      <Box className={show ? classes.bgItemActive : classes.bgItem}/>
    </Box>
  );
};

const BurgerMenu = ({ headerPaths, }) => {

  const location = useLocation();

  const langArr =
  [
    { id: 'ky', title: 'Кыргызча', img: kyrIcon, },
    { id: 'ru', title: 'Русский', img: russianIcon, },
    { id: 'en', title: 'English', img: englishIcon, },
  ];

  const [ anchorEl, setAnchorEl, ] = useState(null);
  const [ show, setShow, ] = useState(false);

  useEffect(() => {
    setShow(false);
    setAnchorEl(prev => null);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ location, ]);

  const handleOpenAnchor = (event) => {
    setShow(prev => !prev);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setShow(prev => !prev);
    setAnchorEl(prev => null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      <Button aria-describedby={id} onClick={handleOpenAnchor} sx={{ background: 'transperent', }}>
        <Burger show={show}/>
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          '& .MuiPopover-paper': {
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',

            padding: '20px',
            width: '100%',

            top: '50px !important',
            right: '0 !important',
            left: 'initial !important',
          },
        }}
      >
        {headerPaths.map((item, i) => <NavigationLink key={i} url={item.url} name={item.name}/>)}
        {langArr.map((lang) =>
          <Box
            key={lang.id}
            onClick={() => changeLang(lang.id)}
            className={lang.id === i18n.language ? classes.activeLangBtn : classes.langBtn}
          >
            <Box component={'img'} src={lang.img} alt='language icon'/>
            {lang.title}
          </Box>
        )}
      </Popover>
    </div>
  );

};

export default BurgerMenu;
