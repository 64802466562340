import React from 'react';
import { Box, Typography, } from '@mui/material';

import { useNavigate, } from 'react-router-dom';
import classes from './newsForm.module.scss';
import { useSelector, } from 'react-redux';


const MAX_LENGTH = 65;

const NewsForm = () => {
  const { newsList, newsListStatus, } = useSelector(state => state.news);
  const navigate = useNavigate();

  const handleNavigate = (id) => {
    navigate(id);
  };

  return (
    <Box className={classes.grid}>
      <Box className={classes.allNews} onClick={e => handleNavigate(newsList?.results?.[ 0 ]?.slug)}>
        <Box className={classes.newsImg} component='img' src={newsList?.results?.[ 0 ]?.news_images?.[ 0 ]?.image}></Box>
        <Box className={classes.titleFirst}>
          <Typography className={classes.titleMore}>{newsList?.results?.[ 0 ]?.title?.slice(0, 65)}</Typography>
        </Box>
      </Box>

      <Box className={classes.allColumn}>
        {newsListStatus === 'succeeded' && newsList?.results?.slice(1, 4).map((item, idx) =>
          <Box className={classes.column} onClick={e => handleNavigate(item?.slug)}>
            <Box className={classes.newsIcons}>
              <img className={classes.newsImages} alt='' src={item?.news_images?.[ 0 ]?.image}></img>
            </Box>
            <Box className={classes.titleSecond}>
              <Typography className={classes.description}>
                {item?.title?.slice(0, MAX_LENGTH)}
                {item?.title.length > MAX_LENGTH && '...'}
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default NewsForm;
