// components
import { Box, Typography, } from '@mui/material';
import { Dropdown, } from 'components/UI/Dropdown';

// hooks
import { useEffect, } from 'react';
import { useTranslation, } from 'react-i18next';
import { useDispatch, useSelector, } from 'react-redux';

// requests
import { getDistrictsList, getRuralsList, getVillagesList, } from 'redux-api/slices/placesSlice';

import classes from './voteForm.module.scss';

const VoteForm = ({ dataVote, handleOnChangeVote, }) => {
  // INITIAL ----------------

  const dropList = [ { id: 'Мужской пол', title: 'M', }, { id: 'Женский пол', title: 'Ж', }, ];
  const dispatch = useDispatch();
  const { districtsList, regionsList, ruralsList, regionsCityList, villagesList, } = useSelector(state => state.places);

  const city = regionsCityList?.find((item) => item.id === dataVote.region);
  const { t, } = useTranslation();
  // REQUESTS ------------------

  useEffect(() => {
    if (dataVote.region && !city) {
      dispatch(getDistrictsList(dataVote.region));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ dataVote.region, dispatch, ]);

  useEffect(() => {
    if (dataVote.district) {
      dispatch(getRuralsList(dataVote.district));
    }
  }, [ dataVote.district, dispatch, ]);

  useEffect(() => {
    if (dataVote.rural) {
      dispatch(getVillagesList(dataVote.rural));
    }
  }, [ dataVote.rural, dispatch, ]);

  return (
    <Box component={'form'}>
      <Typography className={classes.titleStat}>{t('fillVote')}</Typography>
      <Dropdown name='gender' title={t('gender')} dropList={dropList} dropValue={dataVote.gender} handleOnChange={handleOnChangeVote} required={true}/>
      <Dropdown name='region' title={t('region')} dropList={[ ...regionsList, ...regionsCityList, ]} dropValue={dataVote.region} handleOnChange={handleOnChangeVote} required={true}/>
      {!city && (
        <>
          <Dropdown name='district' title={t('district')} dropList={districtsList} dropValue={dataVote.district} handleOnChange={handleOnChangeVote} required={true}/>
          <Dropdown name='rural' title={t('village')} dropList={ruralsList} dropValue={dataVote.rural} handleOnChange={handleOnChangeVote} required={true}/>
          <Dropdown name='village' title={t('villageGovernment')} dropList={villagesList?.results} dropValue={dataVote.village} handleOnChange={handleOnChangeVote} required={true}/>
        </>
      )}
    </Box>
  );
};

export default VoteForm;
