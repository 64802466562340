import { createAsyncThunk, createSlice, } from '@reduxjs/toolkit';
import { REQUEST_STATUSES, } from 'common/constants';
import { addQueryCases, } from 'common/helpers';

const name = 'news';
const ENDPOINTS = { NEWS: '/api/v1/news/', };

export const getNewsList = createAsyncThunk(
  `${name}/getNewsList`,
  async (params, { extra: api, }) => {
    const response = await api.get(`${ENDPOINTS.NEWS}`, { params, });
    return response.data;
  }
);

export const getNews = createAsyncThunk(
  `${name}/getNews`,
  async (id, { extra: api, }) => {
    const response = await api.get(`${ENDPOINTS.NEWS}${id}`);
    return response.data;
  }
);


// news_Id
export const getNewsMore = createAsyncThunk(
  `${name}/getNewsMore`,
  async (id, { extra: api, }) => {
    const params = { news_id: id, };
    const response = await api.get(`${ENDPOINTS.NEWS}`, { params, });
    return response.data;
  }
);

const initialState = {
  newsStatus: REQUEST_STATUSES.NOT_REQUESTED,
  news: {},
  newsError: null,

  newsMoreStatus: REQUEST_STATUSES.NOT_REQUESTED,
  newsMore: {},
  newsMoreError: null,

  newsListStatus: REQUEST_STATUSES.NOT_REQUESTED,
  newsList: {},
  newsListError: null,
};

const newsSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers(builder) {
    addQueryCases(builder, getNews, {
      status: 'newsStatus',
      data: 'news',
      error: 'newsError',
      options: { concat: false, },
    });
    addQueryCases(builder, getNewsMore, {
      status: 'newsMoreStatus',
      data: 'newsMore',
      error: 'newsMoreError',
      options: { concat: false, },
    });
    addQueryCases(builder, getNewsList, {
      status: 'newsListStatus',
      data: 'newsList',
      error: 'newsListError',
      options: { concat: false, },
    });
  },
});

export default newsSlice;
