import { Box, Typography, } from '@mui/material';

import classes from './employee.module.scss';
// eslint-disable-next-line
const EmployeeCard = ({ employee }) => {
  return (
    <Box className={classes.employeeCard}>
      <Box className={classes.employeeImg} component={'img'} src={employee.image} alt='employee image'/>
      <Box className={classes.insideContent}>
        <Typography className={classes.name}>{employee.name}</Typography>
        <Typography className={classes.position}>{employee.position.slice(0, 60)}</Typography>
        <Typography className={classes.contact}>{employee.contact}</Typography>
      </Box>
    </Box>
  );
};

export default EmployeeCard;
