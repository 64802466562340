import { OutlinedInput, MenuItem, FormControl, Select, Typography, } from '@mui/material';

import classes from './dropdown.module.scss';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: (ITEM_HEIGHT * 4.5) + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


const Dropdown = ({ name, title, dropList, dropValue, handleOnChange, required = false, }) => {
  return (
    dropValue.length === 0 ? (
      <FormControl className={classes.dropWrapper}
        sx={{
          '& .MuiOutlinedInput-notchedOutline': { border: '1px solid #eb516d', '&:hover': { border: '1px solid #eb516d', }, },
          '& .Mui-focused .MuiOutlinedInput-notchedOutline': { border: '1px solid #eb516d', },
        }}
      >
        <Select
          name={name}
          displayEmpty
          value={dropValue}
          onChange={handleOnChange}
          input={<OutlinedInput />}
          renderValue={(selected) => {
            const selectedTitle = dropList?.find((item) => item.id === selected);
            if (!selected || !selectedTitle) {
              return <Typography className={classes.dropTitle}>{title}</Typography>;
            }
            return selectedTitle?.title + ' ' + (selectedTitle?.hall || '');
          }}
          MenuProps={MenuProps}
          inputProps={{ 'aria-label': 'Without label', }}
          required={required}
        >
          <MenuItem disabled value="">
            <em>{title}</em>
          </MenuItem>
          {dropList?.map((place) => (
            <MenuItem
              key={place.id}
              value={place.id}
            >
              {place?.title}
              {place.hall && (
                ' ' + place.hall
              )}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    ) : (
      <FormControl className={classes.dropWrapper}

      >
        <Select
          name={name}
          displayEmpty
          value={dropValue}
          onChange={handleOnChange}
          input={<OutlinedInput />}
          renderValue={(selected) => {
            const selectedTitle = dropList?.find((item) => item.id === selected);
            if (!selected || !selectedTitle) {
              return <Typography className={classes.dropTitle}>{title}</Typography>;
            }
            return selectedTitle?.title + ' ' + (selectedTitle?.hall || '');
          }}
          MenuProps={MenuProps}
          inputProps={{ 'aria-label': 'Without label', }}
          required={required}
        >
          <MenuItem disabled value="">
            <em>{title}</em>
          </MenuItem>
          {dropList?.map((place) => (
            <MenuItem
              key={place.id}
              value={place.id}
            >
              {place?.title}
              {place.hall && (
                ' ' + place.hall
              )}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    )
  );
};

export default Dropdown;
