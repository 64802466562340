import React from 'react';

import NavigationLink from './Link';

const NavigationMenu = ({ headerPaths, }) => {
  const lang = localStorage.getItem('lang') || 'ru';

  return (
    <>
      {headerPaths.slice(0, 6).map((item, i) => (<NavigationLink classess={lang === 'ky' ? 'linkItemForKy' : 'linksItem'} key={i} url={item.url} name={item.name}/>))}
    </>
  );

};

export default NavigationMenu;
