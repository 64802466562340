// components
import { Box, Typography, } from '@mui/material';
import { EmployeeCard, } from 'components/EmployeeCard';
import { SkeletonCard, } from 'components/SkeletonCard';
import PaginationComponent from 'components/UI/Pagination/PaginationComponent';
import Empty from 'components/Empty/Empty';
import { Element, } from 'react-scroll';
// hooks
import { useEffect, useState, } from 'react';
import { useTranslation, } from 'react-i18next';
import { useDispatch, useSelector, } from 'react-redux';
// requested
import { getEmployers, } from 'redux-api/slices/employesSlice';

import classes from './team.module.scss';

const TeamList = () => {
  const { employers, employersStatus, } = useSelector(state => state.employers);
  const dispatch = useDispatch();
  const [ page, setPage, ] = useState(1);
  const limitOrOffset = 12;
  const lengthItems = Math.ceil((employers?.count || 0) / limitOrOffset);
  const { t, } = useTranslation();

  useEffect(() => {
    dispatch(getEmployers({ limit: limitOrOffset, offset: (page - 1) * limitOrOffset, }));
  }, [ dispatch, page, ]);

  if (employersStatus === 'requested') {
    return (
      <>
        <Element name='ourTeam'></Element>
        <Typography className={classes.title}>{t('ourTeamAboutUs')}</Typography>
        <Box className={classes.teamList}>
          {
            [ ...Array(8), ].map((i) =>
              <SkeletonCard
                key={i}
                variant='rounded'
                width='285px'
                height='485px'
                animation='wave'
              />)
          }
        </Box>
      </>
    );
  }

  return (
    <>
      <Element name='ourTeam'></Element>
      <Typography className={classes.title}>{t('ourTeamAboutUs')}</Typography>
      {
        !!employers?.count
          ?
          <Box className={classes.teamList}>
            {employers?.results?.map((employee, i) =>
              <EmployeeCard key={i} employee={employee}/>
            )}
          </Box>
          :
          <Empty/>
      }
      <PaginationComponent
        scrollTo='ourTeam'
        page={page}
        setPage={setPage}
        length={lengthItems}
      />
    </>
  );
};

export default TeamList;
