import React from 'react';
import { Box, Container, Typography, Grid, } from '@mui/material';
import classes from './footer.module.scss';
// import { Link, } from 'react-scroll';
import logo from 'assets/icons/whiteLogoOthersLang.svg';
import instagram from 'assets/icons/instagram.svg';
import facebook from 'assets/icons/facebook.svg';
import youtube from 'assets/icons/youtube.svg';
import telegram from 'assets/icons/telegram.svg';
import { Link, } from 'react-router-dom';
import { useTranslation, } from 'react-i18next';

const FooterComponent = () => {

  const { t, } = useTranslation();


  const firstColumn = [ { label: t('aboutUnion'), link: '/about', },
    { label: t('news'), link: '/news', },
    { label: t('legislation'), link: '/legislation', },
    { label: t('decrees'), link: '/decrees', },
    { label: t('votes'), link: '/vote', },
    { label: t('freqAskQues'), link: '/questions', },
    { label: t('tests'), link: '/tests', },
    { label: t('information'), link: '/info', },
    { label: t('conference'), link: '/conference', },
    { label: t('vacancy'), link: '/vacancy', }, ];

  return (
    <Box component='footer' p={'80px 0'} className={classes.footer}>
      <Container>
        <Box>
          <Link to='/'>
            <img alt='logo' src={logo}></img>
          </Link>
        </Box>
        <Grid container className={classes.columns}>
          <Grid flexDirection={'column'} display={'flex'} className={classes.firstColumn}>
            {firstColumn.map((column, idx) => (
              <Link to={column.link} className={classes.label} key={column.label}>{column.label}</Link>
            ))}
          </Grid>
          <Grid color={'#fff'} className={classes.secondColumn}>
            <Typography variant='h6'>
              {t('contacts')}
            </Typography>
            <Typography sx={{ flexDirection: 'column', display: 'flex', marginTop: '5px', }}>
              <a className={classes.label} href='tel:312626151'>+996 312 62-61-51</a>
              <a className={classes.label} href='tel:312626150'>+996 312 62-61-50</a>
              <a className={classes.label} href='tel:312620883'>+996 312 62-08-83</a>
            </Typography>
            <Typography>msu.kg@mail.ru</Typography>
            <Box className={classes.icons}>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://instagram.com/soyuzmsukr"
              >
                <img className={classes.icon} src={instagram} alt='instagram'/>
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.facebook.com/MSU45331/"
              >
                <img className={classes.icon} src={facebook} alt='facebook'/>
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.youtube.com/channel/UCmaSfjJ-zgfHaZALjTx5mCQ/featured"
              >
                <img className={classes.icon} src={youtube} alt='youtube'/>
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://t.me/msu_union_info"
              >
                <img className={classes.icon} src={telegram} alt='telegram'/>
              </a>
            </Box>
          </Grid>
          <Grid className={classes.thirdColumn}>
            <Typography variant='h6'>
              {t('address')}
            </Typography>
            <Typography sx={{ marginTop: '8px', }}>
              {t('infoFooter')}
            </Typography>
            <Typography>
              Пн-Пт: 09:00-18:00
            </Typography>
          </Grid>
          <Grid>
            <iframe
              title='msu'
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2923.8853834260553!2d74.61067881547267!3d42.875263279155824!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x389eb7c6db1325bd%3A0x143412e2b3e83853!2zS3lyZ3l6dGVsZWNvbSBKU0MsIDk2INGD0LsuINCa0LjQtdCy0YHQutCw0Y8sINCR0LjRiNC60LXQug!5e0!3m2!1sru!2skg!4v1671513248253!5m2!1sru!2skg"
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              style={{
                width: '100%',
                height: 280,
                border: 0,
                marginTop: '20px',
              }}/>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default FooterComponent;
