// Components
import { AppBar, Box, Toolbar, Container, } from '@mui/material';
import { SearchItem, } from './HeaderComponents/Search';
import { Navigation, } from './HeaderComponents/Navigation';
import { LanguageMenu, } from './HeaderComponents/LanguageMenu';
import { SelectItem, } from './HeaderComponents/SelectItem';
import { BurgerMenu, } from './HeaderComponents/BurgerMenu';

// Constants
import { PATHS, } from 'common/constants';

// Icon
import LogoSvg from 'assets/icons/logoOthersLang.svg';

// Hooks
import { useTranslation, } from 'react-i18next';
import { useNavigate, useLocation, } from 'react-router-dom';

import classes from './header.module.scss';

const Header = () => {
  const { t, } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();


  const headerPaths = [
    { name: t('about'), url: PATHS.about, },
    { name: t('news'), url: PATHS.news, },
    { name: t('legislation'), url: PATHS.legislation, },
    { name: t('decrees'), url: PATHS.decrees, },
    { name: t('questions'), url: PATHS.questions, },
    { name: t('conference'), url: PATHS.conference, },
  ];

  const selectPaths = [
    { name: t('votes'), url: PATHS.vote, },
    { name: t('tests'), url: PATHS.tests, },
    { name: t('information'), url: PATHS.info, },
    { name: t('vacancy'), url: PATHS.vacancy, },
  ];

  return (
    <>
      <Box className={classes.headerPosition}>
        <AppBar component='nav' className={classes.appBar}>
          <Container>

            {/* Desktop header */}
            <Toolbar className={classes.toolBar}>
              <Box className={classes.logo} onClick={() => navigate('/')} component={'img'} src={LogoSvg} alt='logo icon'/>
              <Navigation headerPaths={headerPaths}/>
              <Box className={classes.flexList}>
                <SelectItem selected={location.pathname === '/' ? t('others') : ''} name={t('others')} menuContent={selectPaths}/>
                <SearchItem/>
                <LanguageMenu />
              </Box>
            </Toolbar>

            {/* Mobile header */}
            <Toolbar className={classes.modileToolbar}>
              <Box className={classes.logo} onClick={() => navigate('/')} component={'img'} src={LogoSvg} alt='logo icon'/>
              <Box className={classes.flexList}>
                <SearchItem/>
                <LanguageMenu />
                <BurgerMenu headerPaths={[ ...headerPaths, ...selectPaths, ]}/>
              </Box>
            </Toolbar>

          </Container>
        </AppBar>
      </Box>
    </>
  );
};

export default Header;
