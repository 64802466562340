import React from 'react';
// components
import { Box, } from '@mui/material';
import { Hero, } from 'components/Home/Hero';
import { MainContentBlock, } from 'components/Home/MainContentBlock';
import { Partners, } from 'components/Home/Partners';
import { Test, } from 'components/Home/Test';
import { Conferences, } from 'components/Home/Conferences';
import { Votes, } from 'components/Home/Votes';
import { News, } from 'components/Home/News';

// others
import { useTranslation, } from 'react-i18next';
import classes from './home.module.scss';
import { Information, } from 'components/Home/Information';

const Home = () => {
  const { t, } = useTranslation();

  return (
    <Box className={classes.main}>
      <Hero/>
      <MainContentBlock title={t('news')} link="/news"
        concat sliceName='news' listOf='newsList'>
        <News/>
      </MainContentBlock>

      <Test/>

      <MainContentBlock title={t('information')} link="/info"
        concat sliceName='information' listOf='information'>
        <Information/>
      </MainContentBlock>

      <MainContentBlock title={t('votes')} link="/vote"
        concat sliceName='places' listOf='votesList'>
        <Votes/>
      </MainContentBlock>

      <MainContentBlock title={t('partners')} link="/partners"
        showMoreBtn={false}>
        <Partners/>
      </MainContentBlock>

      <MainContentBlock title={t('conferenceTitle')} link="/conference"
        concat sliceName='conferences' listOf='conferences'>
        <Conferences/>
      </MainContentBlock>
    </Box>
  );
};

export default Home;
