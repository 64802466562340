import { createSlice, createAsyncThunk, } from '@reduxjs/toolkit';
import { REQUEST_STATUSES, } from 'common/constants';
import { addQueryCases, } from 'common/helpers';

const name = 'vacancies';

const ENDPOINTS = { VACANCIES: '/api/v1/vacancies/', };

export const getVacancies = createAsyncThunk(
  `${name}/getVacancies`,
  async (params, { extra: api, }) => {
    const response = await api.get(ENDPOINTS.VACANCIES, { params, });
    return { results: response.data, };
  }
);

const initialState = {
  vacanciesStatus: REQUEST_STATUSES.NOT_REQUESTED,
  vacancies: [],
  vacanciesError: null,
};

const vacanciesSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers(builder) {
    addQueryCases(builder, getVacancies, {
      status: 'vacanciesStatus',
      data: 'vacancies',
      error: 'vacanciesError',
    });
  },
});


export default vacanciesSlice;
