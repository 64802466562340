import { Box, Typography, } from '@mui/material';
import { WideButton, } from 'components/UI/CustomButton';
import { useTranslation, } from 'react-i18next';
import { useNavigate, } from 'react-router-dom';

import classes from './newsCard.module.scss';

const NewsCard = (props) => {
  const { variant = 'flexCard', item = null, page = 'news', button = false, titleLength = 80, } = props;

  const { t, i18n, } = useTranslation();
  const lang = i18n.language;

  const { title, file, } = item;

  const content = item?.[ 'file_' + lang ] ?? item?.file_ky ?? file;

  const navigate = useNavigate();
  const handleNavigate = () => {
    if (!page) return;
    navigate(`/${page}/${item.slug}`);
  };

  const handleOpenFile = () => {
    window.open(content);
  };

  return (
    <Box className={classes[ variant ]}>
      <Box onClick={handleNavigate} className={classes.newsImg} component='img' src={item?.news_images?.[ 0 ].image ?? item.image}/>
      <Box onClick={handleNavigate} className={classes.descNews}>
        <Typography className={classes.description}>{ title.length > titleLength ? title?.slice(0, titleLength) + '...' : title}</Typography>
        {
          button
        &&
        <WideButton
          onClick={handleOpenFile}
          component='a'
          href={content}
          download={!!content}
        >
          {t('download')}
        </WideButton>
        }
      </Box>
    </Box>
  );
};

export default NewsCard;
