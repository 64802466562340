import React, { useEffect, useState, } from 'react';
import { FormControl, Select, MenuItem, Typography, Box, } from '@mui/material';

import NavigationLink from '../Navigation/Link';
import { changeLang, } from 'common/helpers';

const SelectItem = (props) => {
  const { menuContent, name, lang, selected = '', } = props;
  const [ extraMenu, setExtraMenu, ] = useState(name || lang);

  useEffect(() => {
    if (name) {
      setExtraMenu(prev => name);
    }
  }, [ name, ]);
  const handleMenuChange = (e) => {
    setExtraMenu(e.target.value);
  };

  return (
    <>
      <FormControl sx={{ minWidth: 80, }}>
        <Select
          labelId="demo-simple-select-autowidth-label"
          id="demo-simple-select-autowidth"
          value={selected || extraMenu}
          onChange={handleMenuChange}
          autoWidth
          displayEmpty
          inputProps={{ 'aria-label': 'Without label', }}
          sx={{ '& .MuiSelect-outlined': { padding: '9px 40px 8px 12px', }, }}
        >
          <MenuItem sx={{ display: 'none', }} value={name}>
            {name}
          </MenuItem>
          {menuContent.map((item, i) => (
            item.url ?
              <MenuItem value={i} key={i}>
                <NavigationLink classess={'linkItem'} url={item.url} name={item.name}/>
              </MenuItem>
              :
              <MenuItem onClick={() => changeLang(item.id)} value={item.title} key={i}>
                <Typography> <Box component={'img'} src={item.img} alt='language icon'/> {item.title}</Typography>
              </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );

};

export default SelectItem;
