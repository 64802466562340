// importing  Routes ;
import { Routes, Route, } from 'react-router-dom';
import { PATHS, } from './common/constants.js';

// importing Redux components
import { Provider, } from 'react-redux';
import { store, } from 'redux-api/store';

//// import components
import CallButton from 'components/CallButton/CallButton.js';
import { Home, Vacancy, Decrees, Information, Legislation, News, Questions, Tests, Conference, Vote, SearchResult, About, VoteId, NotFound, } from 'pages/index.js';
import Layout from 'components/Base/Layout/Layout.js';
import NewsId from 'pages/NewsId/NewsId.js';
/// importing MUI components
import { CssBaseline, ThemeProvider, } from '@mui/material';
import theme from 'theme';

// importing scss
import './helper.scss';
import Statistic from 'pages/Statistic/Statistic.js';

const App = () => {
  return (
    <>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Routes>
            <Route path={''} element={<Layout />} >
              <Route path={PATHS.main} element={<Home />} />
              <Route path={PATHS.about} element={ <About /> } />
              <Route path={PATHS.info} element={ <Information /> } />
              <Route path={PATHS.vacancy} element={ <Vacancy /> } />
              <Route path={PATHS.decrees} element={ <Decrees /> } />
              <Route path={PATHS.legislation} element={ <Legislation /> } />
              <Route path={PATHS.questions} element={ <Questions /> } />
              <Route path={PATHS.news} element={ <News /> } />
              <Route path={PATHS.newsId} element={ <NewsId />} />
              <Route path={PATHS.tests} element={ <Tests /> } />
              <Route path={PATHS.conference} element={ <Conference /> } />
              <Route path={PATHS.vote} element={ <Vote /> } />
              <Route path={PATHS.voteId} element={ <VoteId/>}/>
              <Route path={PATHS.searchResult} element={<SearchResult />} />
            </Route>
            <Route path={PATHS.statistic} element={<Statistic />} />
            <Route path='*' element={<NotFound/>} />
          </Routes>
          <CallButton />
        </ThemeProvider>
      </Provider>
    </>
  );
};

export default App;
