import { useState, useEffect, } from 'react';
import PaginationComponent from 'components/UI/Pagination/PaginationComponent';
import { useSelector, useDispatch, } from 'react-redux';
import { getOurprojects, } from 'redux-api/slices/ourprojectsSlice';

const OurProjectsPagination = () => {
  const { ourprojects, } = useSelector(state => state.ourprojects);
  const [ page, setPage, ] = useState(1);
  const offset = 4;
  const lengthItems = Math.ceil((ourprojects.count || 0) / offset);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getOurprojects({ limit: 4, offset: (page - 1) * offset, }));
  }, [ dispatch, page, ]);

  return (
    <PaginationComponent
      page={page}
      setPage={setPage}
      length={lengthItems}
    />
  );
};

export default OurProjectsPagination;
