import * as React from 'react';
import { Box, } from '@mui/system';
import { Typography, } from '@mui/material';
import { Link, } from 'react-router-dom';

import classes from './breadCrumbs.module.scss';
import { useTranslation, } from 'react-i18next';

const BreadCrumbs = ({ path, prevPath, }) => {
  const { t, } = useTranslation();
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: '5px',
        mt: 2.5,
        marginBottom: '46px',
        '& p': { whiteSpace: 'pre', fontSize: '14px', },
      }}
    >
      <Link to="/" style={{ textDecoration: 'none', }}>
        <Typography className={classes.prevPath}>{t('main')}</Typography>
      </Link>
      <Typography className={classes.space}>/</Typography>
      <Typography>{prevPath}</Typography>
      <Typography sx={{ fontWeight: 'bold', }} className={classes.currentPath}>{path}</Typography>

    </Box>
  );
};

export default BreadCrumbs;
