import { createAsyncThunk, createSlice, } from '@reduxjs/toolkit';
import { REQUEST_STATUSES, } from 'common/constants';
import { addQueryCases, } from 'common/helpers';

const name = 'conferences';

const ENDPOINTS = { CONFERENCES: '/api/v1/conferences/', };

export const getConferences = createAsyncThunk(
  `${name}/getConferences`,
  async (params = { limit: 9, offset: 0, }, { extra: api, }) => {
    const response = await api.get(ENDPOINTS.CONFERENCES, { params, });
    return response.data;
  }
);

const initialState = {
  conferencesStatus: REQUEST_STATUSES.NOT_REQUESTED,
  conferences: [],
  conferencesError: null,
};

const conferencesSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers(builder) {
    addQueryCases(builder, getConferences, {
      status: 'conferencesStatus',
      data: 'conferences',
      error: 'conferencesError',
      options: false,
    });
  },
});

export default conferencesSlice;
