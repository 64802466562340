import React from 'react';
import { Link, } from 'react-scroll';
import Pagination from '@mui/material/Pagination';


const PaginationComponent = ({ length, page, scrollTo = '', setPage, style, }) => {
  const handleChangePage = (_, clickedPage) => {
    setPage(clickedPage);
  };

  if (length <= 1) {
    return <></>;
  }

  return (
    <>
      <Link to={scrollTo || 'scroll'} spy={true} smooth={true} duration={500}>
        <Pagination
          onChange={(e, clickedPage) => handleChangePage(e, clickedPage)}
          page={page}
          count={length}
          variant="outlined"
          shape="rounded"
          sx={{ display: 'flex', justifyContent: 'center', marginTop: '80px', }}
        />
      </Link>
    </>
  );
};

export default PaginationComponent;
