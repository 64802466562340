// modules
import React from 'react';
// components
import { Button, Container, Typography, } from '@mui/material';
// constants
// hooks
import { useTranslation, } from 'react-i18next';
import { useNavigate, } from 'react-router-dom';
import { useSelector, } from 'react-redux';
// style
import styled from '@emotion/styled';
import classes from './main.module.scss';

const StyledShowMoreButton = styled(Button)(({ theme, }) => ({
  textTransform: 'none',
  padding: '3px 16px ',
  display: 'block',
  border: '1px solid #706E6B',
  borderRadius: '4px',
  marginTop: '40px',

  '&:where(:hover, :focus)': { backgroundColor: '#fff', },

  '&:hover': { boxShadow: '0px 0px 3px rgba(0, 0, 0, 0.25)', },

  '&:focus': { boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.15)', },

  '& .buttonLink': {
    fontSize: '16px',
    lineHeight: '20px',
    textAlign: 'center',
    color: ' #706E6B',
    textDecoration: 'none',
  },
  [ theme.breakpoints.down(500) ]: {
    width: '100%',
    padding: '6px 0',
    marginTop: '26px',
  },
}));

const StyledTitle = styled(Typography)(({ theme, }) => ({
  fontWeight: '600',
  fontSize: '28px',
  lineHeight: '45px',
  textAlign: 'center',
  color: '#000000',
  marginBottom: '30px',
  [ theme.breakpoints.down(500) ]: { marginBottom: '15px', fontSize: '16px', },
}));

const MainContentBlock = ({ children, title, concat = false, sliceName = '', listOf = '', showMoreBtn = true, link, }) => {
  const { t, } = useTranslation();
  const navigate = useNavigate();

  // function for relocate pages
  const handleNavigate = () => {
    navigate(link);
  };

  const data = useSelector(state => state?.[ sliceName ]?.[ listOf ]);

  const count = concat && !(Array.isArray(data) ? data.length : data?.results?.length);
  return (
    <Container className={[ classes.mainBlock, count ? classes.active : '', ].join(' ')}>
      <StyledTitle>{title}</StyledTitle>
      {children}
      {showMoreBtn &&
        <StyledShowMoreButton onClick={handleNavigate} >
          <Typography className='buttonLink' to={link}>{t('showMore')}</Typography>
        </StyledShowMoreButton>
      }
    </Container>
  );
};

export default MainContentBlock;
