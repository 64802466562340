import React from 'react';
import NavigationMenu from './NavigationMenu';


const Navigation = ({ headerPaths, }) => {

  return (
    <NavigationMenu headerPaths={headerPaths}/>
  );
};

export default Navigation;
