import { Box, } from '@mui/material';
import { VoteList, } from 'components/VoteList';
// hooks
import React, { useEffect, } from 'react';
import { useDispatch, useSelector, } from 'react-redux';

// requests
import { getVotesList, } from 'redux-api/slices/placesSlice';

import classes from './votes.module.scss';

const Votes = () => {

  const { votesList, votesListStatus, } = useSelector(state => state.places);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getVotesList({ limit: 2, offset: 0, }));

    return () => {};
  }, [ dispatch, ]);

  return (
    <Box className={classes.votes}>
      { <VoteList size='full' list={votesList?.results} status={votesListStatus} mainColor='#50B227' bgHover='#42941F'/>}
    </Box>
  );
};

export default Votes;
