import { useState, } from 'react';
import { Select, MenuItem, Box, Tab, Tabs, } from '@mui/material';

import classes from './tabs.module.scss';

const TabsContent = ({ tabsList, setValue, value, valueKey='value', labelKey='label', }) => {

  const [ open, setOpen, ] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Box sx={{ width: '100%', }}>

      {/* DESKTOP TABS */}
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="wrapped label tabs example"
        sx={{
          '& .MuiTabs-flexContainer': { gap: '40px', },
          '& button': { fontSize: '16px !important', },
        }}
        className={classes.tabs}
      >
        {tabsList.map((tab) =>
          <Tab
            className={tab[ valueKey ] === value ? classes.activeLabel : classes.label}
            key={tab[ valueKey ]}
            value={tab[ valueKey ]}
            label={tab[ labelKey ]}
            wrapped
          />
        )}
      </Tabs>

      {/* MOBILE TABS */}
      <Select
        open={open}
        labelId="tabsLabel"
        id="tabsSelect"
        value={ value }
        onClick={() => setOpen(prev => !prev)}
        onChange={(e) => handleChange(e, e.target.value)}
        className={ classes.selectTab }
        sx={{ '& .MuiSelect-select': { minWidth: '90%', padding: '16.5px 5px', paddingRight: '0px !important', }, }}
      >
        {tabsList.map((tab) =>
          <MenuItem
            className={classes.menu}
            key={tab[ valueKey ]}
            value={tab[ valueKey ]}
          >
            {tab[ labelKey ]}
          </MenuItem>
        )}
      </Select>

    </Box>
  );
};

export default TabsContent;
