import { Box, Typography, Button, useMediaQuery, } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import classes from './ourProjects.module.scss';
import { useState, useRef, } from 'react';
import useCollapse from 'react-collapsed';
import { useTranslation, } from 'react-i18next';

const OurProjects = ({ item, }) => {

  const [ isExpanded, setExpended, ] = useState(false);
  const { getCollapseProps, getToggleProps, } = useCollapse({ isExpanded, });
  const mediaDeskTop = useMediaQuery('(max-width: 1050px');
  const isMobile = useMediaQuery('(max-width: 575px');
  const numSlice = isMobile ? -1 : mediaDeskTop ? 130 : 255;
  const { t, } = useTranslation();
  const projectRef = useRef();

  return (
    <Box marginBottom='40px' ref={projectRef}>
      <Box className={classes.projectCard}>
        <Box component="img" className={classes.projectImg} src={item?.image} />
        <Box className={classes.column} {...getToggleProps()}>
          <Typography className={classes.title}>
            {item?.title?.slice(0, 87) }
          </Typography>
          <Typography className={classes.description}>
            {isExpanded ? item?.short_description : item?.short_description.slice(0, numSlice) + '...'}
          </Typography>
          <Button
            className={classes.readBtn }
            variant="contained"
            {...getCollapseProps({ onClick: () => setExpended(prevExpended => !prevExpended), })}
          >
            {isExpanded ? (
              <Typography sx={{ display: 'flex', }}>
                {t('hide')}
                <KeyboardArrowUpIcon />
              </Typography>
            ):(
              <Typography sx={{ display: 'flex', }}>
                {t('readCompletely')}
                <KeyboardArrowDownIcon />
              </Typography>
            )}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default OurProjects;
