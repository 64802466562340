import { Box, Typography, } from '@mui/material';
import aboutHistory from '../../assets/images/aboutHistory.png';
import classes from './infoHistory.module.scss';
import { useTranslation, } from 'react-i18next';

const InfoHistory = () => {

  const { t, }= useTranslation();

  return (
    <Box sx={{ marginTop: '40px', }}>
      <Typography fontWeight="600" fontSize="21px">{t('historyAboutUs')}</Typography>
      <Box className={classes.column} >
        <img className={classes.img} src={aboutHistory} alt=""></img>
        <Typography className={classes.description}>
          {t('infoHistory')}
        </Typography>
      </Box>
    </Box>
  );
};

export default InfoHistory;
