// components
import { Container, } from '@mui/material';
import { BreadCrumbs, } from 'components/BreadCrumbs';
import { HTag, } from 'components/HTag';
import { VoteList, } from 'components/VoteList';
import PaginationComponent from 'components/UI/Pagination/PaginationComponent';
import { TabsContent, } from 'components/UI/TabsContent';

import Empty from 'components/Empty/Empty';
// hooks
import React, { useEffect, useState, } from 'react';
import { useDispatch, useSelector, } from 'react-redux';

// request
import { getTests, getCategories, } from 'redux-api/slices/testsSlice';
import { useTranslation, } from 'react-i18next';

const Tests = () => {
  const { categories: preCategories, tests, testsStatus, } = useSelector(state => state.tests);
  const dispatch = useDispatch();
  const { t, } = useTranslation();
  const [ page, setPage, ] = useState(1);
  const offset = 9;
  const lengthItems = Math.ceil((tests.count || 0) / offset);

  const categories = [ { id: 'standart', title: t('allCategories'), }, ...preCategories, ];
  const [ value, setValue, ] = useState('standart');

  useEffect(() => {
    setValue(categories?.[ 0 ]?.id);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ preCategories, ]);
  useEffect(() => {
    dispatch(getCategories());
  }, [ dispatch, ]);
  useEffect(() => {
    dispatch(getTests({ category: value !== 'standart' ? value: '', limit: 9, offset: (page - 1) * offset, }));
  }, [ dispatch,
    value,
    page, ]);


  if (tests?.count === 0) {
    return (
      <Empty />
    );
  }


  return (
    <Container>
      <BreadCrumbs path={t('tests')}/>
      <HTag tag={'h2'}>
        {t('tests')}
      </HTag>
      <TabsContent valueKey='id' labelKey='title' tabsList={categories} value={value} setValue={setValue} />
      <VoteList list={tests.results} status={testsStatus} mainColor='#0770B8' btnHover='#50B227'/>

      <PaginationComponent
        page={page}
        setPage={setPage}
        length={lengthItems}
      />
    </Container>
  );
};

export default Tests;
