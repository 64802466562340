import React from 'react';

// import styles;
import classes from './htag.module.scss';
/// import MUI;
import { Box, } from '@mui/material';
// components
import { Element, } from 'react-scroll';

const HTag = ({ tag, children, }) => {
  switch (tag) {
  case 'h1':
    return <Element name='scroll'><Box component={'h1'} className={classes.h1}>{children}</Box></Element>;
  case 'h2':
    return <Element name='scroll'><Box component={'h2'} className={classes.h2}>{children}</Box></Element>;
  case 'h3':
    return <Element name='scroll'><Box component={'h3'} className={classes.h3}>{children}</Box></Element>;
  case 'h4':
    return <Element name='scroll'><Box component={'h4'} className={classes.h4}>{children}</Box></Element>;
  default:
    return <></>;
  };
};

export default HTag;
