// components
import { Container, } from '@mui/material';
import { BreadCrumbs, } from 'components/BreadCrumbs';
import { HTag, } from 'components/HTag';
import { VoteSteps, } from 'components/VoteSteps';

// hooks
import { useEffect, } from 'react';
import { useTranslation, } from 'react-i18next';
import { useDispatch, } from 'react-redux';
import { useParams, } from 'react-router-dom';

// requests
import { getRegionsCityList, getRegionsList, getVoteId, } from 'redux-api/slices/placesSlice';

import classes from './voteId.module.scss';

const VoteId = () => {
  const { t, } = useTranslation();
  const { id, } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getVoteId(id));
  }, [ dispatch, id, ]);

  useEffect(() => {
    dispatch(getRegionsList());
    dispatch(getRegionsCityList());
  }, [ dispatch, ]);

  return (
    <Container>
      <BreadCrumbs path={t('votes')}/>
      <HTag tag={'h2'} className={classes.title}>
        {t('votes')}
      </HTag>
      <VoteSteps/>
    </Container>
  );
};

export default VoteId
;
