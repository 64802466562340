import { createAsyncThunk, createSlice, } from '@reduxjs/toolkit';
import { REQUEST_STATUSES, } from 'common/constants';
import { addQueryCases, } from 'common/helpers';

const name = 'ourprojects';

const ENDPOINTS = { OURPROJECTS: '/api/v1/ourprojects/', };

export const getOurprojects = createAsyncThunk(
  `${name}/getOurprojects`,
  async (params, { extra: api, }) => {
    const response = await api.get(ENDPOINTS.OURPROJECTS, { params, });
    return response.data;
  }
);

const initialState = {
  ourprojectsStatus: REQUEST_STATUSES.NOT_REQUESTED,
  ourprojects: [],
  ourprojectsError: null,
};

const ourprojectsSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers(builder) {
    addQueryCases(builder, getOurprojects, {
      status: 'ourprojectsStatus',
      data: 'ourprojects',
      error: 'ourprojectsError',
      options: { concat: false, },
    });
  },
});

export default ourprojectsSlice;
