import React, { useEffect, useState, } from 'react';

// import styles ;
import classes from './conference.module.scss';

// import MUI;
import { Box, Container, useMediaQuery, } from '@mui/material';

// import custom components;
import { BreadCrumbs, } from 'components/BreadCrumbs';
import { HTag, } from 'components/HTag';

/// import react-youtube components;
import PaginationComponent from 'components/UI/Pagination/PaginationComponent';
import { useDispatch, useSelector, } from 'react-redux';
import { getConferences, } from 'redux-api/slices/conferencesSlice';
import { YoutubeList, } from 'components/YoutubeList';
import { useTranslation, } from 'react-i18next';


const Conference = () => {
  const [ page, setPage, ] = useState(1);
  const { count, } = useSelector(state => state.conferences.conferences);
  const { t, } = useTranslation();

  const isMobile = useMediaQuery('(max-width: 575px)');
  const conferencesLimit = isMobile ? 3 : 9;

  const offset = conferencesLimit;
  const lengthItems = Math.ceil((count || 0) / offset);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getConferences({ limit: conferencesLimit, offset: (page-1) * offset, }));

    return () => {};
    // eslint-disable-next-line
  }, [page]);

  return (
    <Container>
      <BreadCrumbs path={t('video')} />
      <Box className={classes.conferenceMainText}>
        <HTag tag={'h2'}>
          {t('video')}
        </HTag>
      </Box>
      <YoutubeList limit={conferencesLimit}/>
      <Box className={classes.conferencePagination}>
        <PaginationComponent page={page} setPage={setPage} length={lengthItems}/>
      </Box>
    </Container>
  );
};

export default Conference;
