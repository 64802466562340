import { createAsyncThunk, createSlice, } from '@reduxjs/toolkit';
import { REQUEST_STATUSES, } from 'common/constants';
import { addQueryCases, } from 'common/helpers';

const name = 'information';
const ENDPOINTS = { INFORMATION: '/api/v1/usefulinformation', };

export const getInformation = createAsyncThunk(
  `${name}/getInformation`,
  async (params = { limit: 100, offset: 0, }, { extra: api, }) => {
    const response = await api.get(ENDPOINTS.INFORMATION, { params, });
    return response.data;
  }
);

const initialState = {
  informationStatus: REQUEST_STATUSES.NOT_REQUESTED,
  information: [],
  informationError: null,
};

const informationSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers(builder) {
    addQueryCases(builder, getInformation, {
      status: 'informationStatus',
      data: 'information',
      error: 'informationError',
      options: false,
    });
  },
});

export default informationSlice;
