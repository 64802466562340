import { Box, } from '@mui/material';
import { Header, } from 'components/Base/Header';
import { CustomButton, } from 'components/UI/CustomButton';
import React from 'react';
import { useNavigate, } from 'react-router-dom';
import classes from './notFound.module.scss';

const NotFound = () => {
  const navigate = useNavigate();

  const handleComeHome = () => {
    navigate('/');
  };

  return (
    <>
      <Header/>
      <Box className={classes.notFound}>
        <Box className={classes.statusCode}>404</Box>
        <Box className={classes.text}>Страница не найдена</Box>
        <CustomButton handleDo={handleComeHome}>
          Перейти на главную
        </CustomButton>
      </Box>
    </>
  );
};

export default NotFound;
