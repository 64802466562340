import React, { useState, useEffect, } from 'react';
import PaginationComponent from 'components/UI/Pagination/PaginationComponent';
import { useSelector, useDispatch, } from 'react-redux';
import { getNewsList, } from 'redux-api/slices/newsSlice';
import { useMediaQuery, } from '@mui/material';

const NewsPagination = ({ scrollTo = '', }) => {
  const { newsList, } = useSelector(state => state.news);
  const [ page, setPage, ] = useState(1);
  const dispatch = useDispatch();
  const isMobile = useMediaQuery('(max-width: 575px');
  const offset = isMobile ? 5 : 8;
  const lengthItems = Math.ceil((newsList.count || 0) / offset);

  useEffect(() => {
    dispatch(getNewsList({ limit: isMobile ? 5 : 8, offset: (page - 1) * offset, }));
  }, [ dispatch,
    page,
    isMobile,
    offset, ]);

  return (
    <PaginationComponent
      page={page}
      setPage={setPage}
      length={lengthItems}
      scrollTo={scrollTo}
    />
  );
};

export default NewsPagination;
