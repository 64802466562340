// components
import { Container, Typography, } from '@mui/material';
import TabsContent from 'components/UI/TabsContent/TabsContent';
import { HTag, } from 'components/HTag';
import { InfoAbout, } from 'components/InfoAbout';
import { InfoBodies, } from 'components/InfoBodies';
import { InfoHistory, } from 'components/InfoHistory';
import { TeamList, } from 'components/TeamList';
import { BreadCrumbs, } from 'components/BreadCrumbs';
import OurProjects from 'components/OurProjects/OurProjects';
import { OurProjectsPagination, } from 'components/OurProjectsPagination';
import Empty from 'components/Empty/Empty';
// hooks
import React, { useState, } from 'react';
import { useSelector, } from 'react-redux';
import { useTranslation, } from 'react-i18next';

import classes from './about.module.scss';

const VALUES = { souz: 'souz', history: 'history', organ: 'organ', projects: 'projects', ourTeam: 'our-team', };


const GetComponentByTab = ({ action, }) => {
  const { ourprojects, } = useSelector(state => state.ourprojects);
  const { t, } = useTranslation();

  switch (action) {
  case VALUES.souz: {
    return <InfoAbout/>;
  }
  case VALUES.history: {
    return <InfoHistory />;
  }
  case VALUES.organ: {
    return <InfoBodies />;
  }
  case VALUES.projects: {
    return <>
      <Typography className={classes.main}>
        {t('projectsAboutUs')}
      </Typography>
      {
        ourprojects?.count
          ?
          ourprojects?.results?.map((item, idx) => (
            <OurProjects item={item} id={idx} />
          ))
          :
          <Empty/>
      }
      <OurProjectsPagination />
    </>;
  }
  case VALUES.ourTeam: {
    return <TeamList/>;
  }
  default:
    return <></>;
  }
};

const About = () => {
  const { t, } = useTranslation();
  const tabsList = [
    { value: VALUES.souz, label: t('aboutUs'), },
    { value: VALUES.history, label: t('historyAboutUs'), },
    { value: VALUES.organ, label: t('organAboutUs'), },
    { value: VALUES.projects, label: t('projectsAboutUs'), },
    { value: VALUES.ourTeam, label: t('ourTeamAboutUs'), },
  ];
  const [ value, setValue, ] = useState(tabsList?.[ 0 ]?.value);


  return (
    <Container className={classes.aboutContainer}>
      <BreadCrumbs path={t('aboutUnion')}/>
      <HTag tag={'h2'}>
        {t('aboutUnion')}
      </HTag>
      <TabsContent tabsList={tabsList} value={value} setValue={setValue} />
      <GetComponentByTab action={value}/>
    </Container>
  );
};

export default About;
