import { Box, } from '@mui/material';
import { NewsList, } from 'components/NewsList';
import React from 'react';
import classes from './news.module.scss';

const News = () => {

  return (
    <Box className={classes.news}>
      <NewsList variant='row' listOf='newsList' count={8} page={'news'}
        sliceName='news' />
    </Box>
  );
};

export default News;
