export const PATHS =
{
  main: '/',
  about: '/about',
  vacancy: '/vacancy',
  decrees: '/decrees',
  info: '/info',
  legislation: '/legislation',
  questions: '/questions',
  news: '/news',
  tests: '/tests',
  conference: '/conference',
  vote: '/vote',
  voteId: '/vote/:id',
  searchResult: '/searchResult',
  statistic: '/statistic/:id',
  newsId: '/news/:id',
};

export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const SENTRY_DSN = process.env.DSN;

export const REQUEST_STATUSES = {
  NOT_REQUESTED: 'notRequested',
  REQUESTED: 'requested',
  SUCCEEDED: 'succeeded',
  FAILED: 'failed',
};
