import { Box, Typography, } from '@mui/material';
import getYouTubeID from 'get-youtube-id';
import React from 'react';
import YouTube from 'react-youtube';


import classes from './youtubeCard.module.scss';

const YoutubeCard = ({ conference, opts, }) => {
  const description = conference.description;

  // conference id (link) for youtube
  const url = getYouTubeID(conference.url);

  // some function from youtube components I don't know why components use this fc;
  const checkElapsedTime = (e) => {
    const duration = e.target.getDuration();
    const currentTime = e.target.getCurrentTime();
    if (currentTime / duration > 0.95) return;
  };

  return (
    <Box
      className={classes.conferenceItem}
      key={conference.id}
    >
      <YouTube
        videoId={url}
        containerClassName="embed embed-youtube"
        onStateChange={(e) => checkElapsedTime(e)}
        opts={opts}
      />
      <Typography className={classes.conferencesDescription}>
        {description.length > 100 ? description.slice(0, 100)+'...' : description}
      </Typography>
    </Box>
  );
};

export default YoutubeCard;
