import React from 'react';
import { Box, Typography, } from '@mui/material';
import classes from './decreesCard.module.scss';
import download from 'assets/icons/download.svg';
import downloadBlue from 'assets/icons/downloadBlue.svg';


const DecreesCard = ({ number, text, date, link, acts = false, }) => {
  const classText = acts ? classes.actsTitle : classes.decreesTitle;

  const handleLink = () => {
    if (!acts) return;
    window.open(link);
  };
  return (
    <Box className={classes.decCard}>
      <Box className={classes.flexRow}>
        <Typography className={classes.number}>
        №{number}
        </Typography>
        <Box className={classes.textWrapper}>
          <Typography className={classText} onClick={handleLink}>
            {text}
          </Typography>
          <Typography className={classes.dateAndBtn}>
            {date}
            {acts
              ?
              <a href={link}>Смотреть</a>
              :
              <a href={link}>Скачать <img className={classes.img} src={downloadBlue} alt="" /></a>
            }
          </Typography>
        </Box>
      </Box>
      {!acts && (
        <Box>
          <a href={link}><img className={classes.desktopImg} src={download} alt="" /></a>
        </Box>
      )}
    </Box>
  );
};

export default DecreesCard;
