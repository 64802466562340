//Default
import React, { useEffect, useState, } from 'react';

//MUI
import { Box, InputAdornment, MenuItem, Select, TextField, InputLabel, FormControl, Button, } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

//LIbraries
import { useDebounce, } from 'use-debounce';

//Styles
import classes from './selects.module.scss';

//Redux-api
import { useDispatch, useSelector, } from 'react-redux';
import { getDecrees, getDistricts, getRegion, getRegionCity, getRurals, } from 'redux-api/slices/placesSlice';
import { useTranslation, } from 'react-i18next';

const Selects = () => {
  const dispatch = useDispatch();
  const { t, } = useTranslation();

  //translation
  const REGION = t('region');
  const DISTRICT = t('district');
  const VILLAGE = t('village');

  //Selectors
  const districts = useSelector(state => state.places.districts);
  const regionCity = useSelector(state => state.places.regionCity);
  const regions = useSelector(state => state.places.regions);
  const villages = useSelector(state => state.places.villages);
  //States

  const [ isDisabled, setIsDisabled, ] = useState({
    district: true,
    villages: true,
  });

  const [ places, setPlaces, ] = useState({
    regions: [ { title: '', id: '', }, ],
    districts: [ { title: '', id: '', }, ],
    villages: [ { title: '', id: '', }, ],
  });
  // console.log(places.districts);
  const [ filters, setFilters, ] = useState({
    region: places.regions?.[ 0 ]?.id,
    district: places.districts?.[ 0 ]?.id,
    village: places.villages?.[ 0 ]?.id,
  });

  const [ value, setValue, ] = useState('');
  const [ debounceValue, ] = useDebounce(value, 1000);
  const [ queryString, setQueryString, ] = useState({});

  //Utils
  const checkForTitle = (arr, field, type, func) => {
    let status = false;
    arr.forEach(async item => {
      if (item.id === field) {
        status = true;
        await dispatch(func(item.id));
        if (type) {
          setIsDisabled({ ...isDisabled, [ type ]: false, });
        }
        return true;
      }
    });
    return status;
  };

  const getSpecDecrees = (arr, field, query) => {
    if (field === REGION || field === DISTRICT || field === VILLAGE) {
      dispatch(getDecrees());
      return;
    }
    arr.forEach(item => {
      if (item.id === field) {
        setQueryString({ [ query ]: item.id, });
        dispatch(getDecrees({ [ query ]: item.id, }));
      }
    });
  };

  //UseEffects
  useEffect(() => {
    dispatch(getDecrees());
    dispatch(getRegionCity());
    dispatch(getRegion());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ dispatch, ]);

  useEffect(() => {
    setPlaces({
      ...places,
      regions: [
        ...regionCity, ...regions,
      ],
      districts: [ ...districts, ],
      villages: [ ...villages, ],
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ regions,
    regionCity,
    districts,
    villages,
    REGION,
    DISTRICT,
    VILLAGE, ]);

  //Handles
  const handleChangeFilters = (type, field) => {
    if (!regionCity.map(item => item.id).includes(field)) {
      if (checkForTitle(regions, field, 'district', getDistricts)) {
        getSpecDecrees(regions, field, 'management__district__region');
      }
      setIsDisabled({ ...isDisabled, 'district': false, 'villages': false, });

    } else {
      getSpecDecrees(regionCity, field, 'regional_city');
      setPlaces({
        ...places,
        districts: [ { title: DISTRICT, id: '', }, ],
        villages: [ { title: VILLAGE, id: '', }, ],

      });
    }
    if (checkForTitle(districts, field, 'villages', getRurals)) {
      getSpecDecrees(districts, field, 'management__district');
    }
    getSpecDecrees(villages, field, 'management');
    setFilters({ ...filters, [ type ]: field, });
  };

  useEffect(() => {
    dispatch(getDecrees({ ...queryString, search: debounceValue, }));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ debounceValue, ]);

  const print = filters ? Object.values(filters) : null;

  if (!filters) {
    localStorage.setItem('filters', JSON.stringify({ region: '', district: '', village: '', }));
    setFilters(JSON.parse(localStorage.getItem('filters')));
  }

  useEffect(() => {
    if (print !== null && filters) {
      if (Object.values(filters)[ 0 ] || Object.values(filters)[ 1 ] || Object.values(filters)[ 2 ]) {
        localStorage.setItem('filters', JSON.stringify(filters));
      }
    }
    if (JSON.parse(localStorage.getItem('filters'))?.region !== filters?.region) {
      setFilters(JSON.parse(localStorage.getItem('filters')));
    }
    return () => null;
  }, [ filters, print, ]);

  const handleClick = () => {
    localStorage.setItem('filters', JSON.stringify({ region: '', district: '', village: '', }));
    document.location.reload();
  };

  const style = {
    '& .Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: 'none', },
    '& .MuiSelect-select': { display: 'flex', alignItems: 'center', minWidth: 200, minHeight: 45, },
  };

  return (
    <Box className={ `${classes.spBox} ${classes.containterOfInputs}` }>
      <form onSubmit={(e) => e.preventDefault()} className={ `${classes.spBox} ${classes.gap}` }>
        <FormControl sx={style}>
          <InputLabel sx={{ top: '-4px', }} id="demo-simple-select-label">{REGION}</InputLabel>
          <Select
            labelId="regionLabel"
            label={REGION}
            id="regionSelect"
            value={filters?.region}
            onChange={ (e) => handleChangeFilters('region', e.target.value) }
            className={ classes.select }
          >
            {places.regions.map((item, i) => <MenuItem key={item?.id ?? i} value={item?.id}>{item?.title}</MenuItem>)}
          </Select>
        </FormControl>
        <FormControl sx={style}>
          <InputLabel sx={{ top: '-4px', }} id="demo-simple-select-label">{DISTRICT}</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label={DISTRICT}
            value={ filters?.district }
            onChange={ (e) => handleChangeFilters('district', e.target.value) }
            className={ classes.select }
            disabled={isDisabled.district}
          >
            {places.districts.map((item, i) => <MenuItem key={item?.id ?? i} value={item?.id}>{item?.title}</MenuItem>)}
          </Select>
        </FormControl>
        <FormControl sx={style}>
          <InputLabel sx={{ top: '-4px', }} id="demo-simple-select-label">{VILLAGE}</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label={VILLAGE}
            value={ filters?.village }
            onChange={ (e) => handleChangeFilters('village', e.target.value) }
            className={ classes.select }
            disabled={isDisabled.villages}
          >
            {places.villages.map((item, i) => <MenuItem key={item?.id ?? i} value={item?.id}>{item?.title}</MenuItem>)}
          </Select>
        </FormControl>
        <Button onClick={handleClick} className={classes.clearBtn}>{t('clear')}</Button>
      </form>
      <form className={classes.formInput} onSubmit={(e) => e.preventDefault()}>
        <TextField
          value={value}
          onChange={(e) => setValue(e.target.value)}
          placeholder={t('search')}
          InputProps={{
            className: classes.input,
            startAdornment: (
              <InputAdornment sx={{ marginLeft: '10px', }} position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </form>
    </Box>
  );
};

export default Selects;
