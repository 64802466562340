// components
import { Box, } from '@mui/material';
import { SkeletonCard, } from 'components/SkeletonCard';
import Empty from 'components/Empty/Empty';
import { VoteCard, } from 'components/VoteCard';

import classes from './voteList.module.scss';

const VoteList = ({ list, status, mainColor, size, btnHover, bgHover, }) => {

  if (status === 'requested' || !list || list?.length === 0) {
    return (
      <Box className={size === 'full' ? classes.voteListFull : classes.voteList}>
        {[ ...Array(6), ].map((item, i) => <SkeletonCard key={i}/>)}
      </Box>
    );
  }

  return (
    <Box className={size === 'full' ? classes.voteListFull : classes.voteList}>
      {list?.map((vote, i) =>
        <VoteCard key={vote.id} vote={vote} mainColor={mainColor} btnHover={btnHover} bgHover={bgHover}/>
      )}
    </Box>
  );
};

export default VoteList;
