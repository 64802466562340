// components
import { Container, } from '@mui/material';
import { BreadCrumbs, } from 'components/BreadCrumbs';
import { DecreesList, } from 'components/decrees/DecreesList';
import { HTag, } from 'components/HTag';
import { useTranslation, } from 'react-i18next';

const Legislation = () => {

  const { t, } = useTranslation();

  return (
    <Container>
      <BreadCrumbs path={t('legislation')}/>
      <HTag tag='h2'>{t('legislation')}</HTag>
      <DecreesList actsActive={true}/>
    </Container>
  );
};

export default Legislation;
