import React, { useState, useRef, } from 'react';
import Box from '@mui/material/Box';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Typography from '@mui/material/Typography';
import parse from 'html-react-parser';
import { Button, } from '@mui/material';
import useCollapse from 'react-collapsed';
import { useTranslation, } from 'react-i18next';
import classes from './accordion.module.scss';


const AccordionComponent = ({ title, description, id, handleChange, expanded, }) => {

  const [ isExpanded, setExpended, ] = useState(false);
  const { getCollapseProps, getToggleProps, } = useCollapse({ isExpanded, });
  const { t, } = useTranslation();
  const projectRef = useRef();

  return (
    <Box ref={projectRef} className={classes.projectCard}>
      <Box className={classes.column} {...getToggleProps()}>
        <Box sx={{ width: '100%', }}>
          <Typography sx={{ fontWeight: '500', }}>
            {title}
          </Typography>
          <Typography sx={{ marginTop: '30px', }}>
            {isExpanded ? parse(description) : parse(description).slice(0, 0)}
          </Typography>
        </Box>

        <Button
          sx={{ display: 'flex !important', }}
          className={classes.readBtn }
          variant="contained"
          {...getCollapseProps({ onClick: () => setExpended(prevExpended => !prevExpended), })}
        >
          {isExpanded ? (
            <Typography sx={{ display: 'flex', }}>
              {t('hide')}
              <KeyboardArrowUpIcon />
            </Typography>
          ):(
            <Typography sx={{ display: 'flex', }}>
              {t('uncover')}
              <KeyboardArrowDownIcon />
            </Typography>
          )}
        </Button>
      </Box>
      <hr className={classes.hr}></hr>

    </Box>
  );
};

export default AccordionComponent;
