import { Box, Typography, } from '@mui/material';
import React from 'react';
import classes from './hero.module.scss';
import logo from 'assets/icons/logo.svg';
import banner from 'assets/images/banner.png';
import { useTranslation, } from 'react-i18next';

const Hero = () => {
  const { t, } = useTranslation();
  return (
    <>
      <Box className={classes.hero} sx={{ background: `url("${banner}") no-repeat center`, }}>
        <Box className={classes.heroContent}>
          <Box className={classes.logo} component='img' src={logo}/>
          <Typography className={classes.logoText}>
            КР ЖӨБ Союзу Союз МСУ КР Union LSG KG
          </Typography>
        </Box>
        <Typography className={classes.caption}>{t('union')}</Typography>
      </Box>
    </>
  );
};

export default Hero;
