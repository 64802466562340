import { createAsyncThunk, createSlice, } from '@reduxjs/toolkit';
import { REQUEST_STATUSES, } from 'common/constants';
import { addQueryCases, } from 'common/helpers';

const name = 'acts';

const ENDPOINTS = { ACTS: '/api/v1/acts/', };

export const getActs = createAsyncThunk(
  `${name}/getActs`,
  async (params, { extra: api, }) => {
    const response = await api.get(`${ENDPOINTS.ACTS}`, { params, });
    return response.data;
  }
);

const initialState = {
  actsStatus: REQUEST_STATUSES.NOT_REQUESTED,
  actsError: null,
  acts: {},
  /**
    * Acts
    * @param {string} id
    * @param {string} act_number
    * @param {string} title
    * @param {string} link
    */
};

const actsSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers(builder) {
    addQueryCases(builder, getActs, {
      status: 'actsStatus',
      data: 'acts',
      error: 'actsError',
      options: { concat: false, },
    });
  },
});

export default actsSlice;
