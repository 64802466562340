import { Skeleton, } from '@mui/material';

const SkeletonCard = (props) => {
  const { variant='rounded', width='100%', height='140px', } = props;
  return (
    <Skeleton variant={variant} width={width} height={height} animation='wave' {...props}/>
  );
};

export default SkeletonCard;
