import decreesSlice from './slices/placesSlice';
import employesSlice from './slices/employesSlice';
import exampleSlice from './slices/slice';
import questionsSlice from './slices/questionsSlice';
import vacanciesSlice from './slices/vacanciesSlice';
import placesSlice from './slices/placesSlice';
import newsSlice from './slices/newsSlice';
import conferencesSlice from './slices/conferencesSlice';
import actsSlice from './slices/actsSlice';
import informationSlice from './slices/informationSlice';
import statisticSlice from './slices/statisticSlice';
import testsSlice from './slices/testsSlice';
import searchSlice from './slices/searchSlice';
import ourprojectsSlice from './slices/ourprojectsSlice';

export const reducerObj = {
  [ exampleSlice.name ]: exampleSlice.reducer,
  [ employesSlice.name ]: employesSlice.reducer,
  [ decreesSlice.name ]: decreesSlice.reducer,
  [ questionsSlice.name ]: questionsSlice.reducer,
  [ vacanciesSlice.name ]: vacanciesSlice.reducer,
  [ placesSlice.name ]: placesSlice.reducer,
  [ newsSlice.name ]: newsSlice.reducer,
  [ conferencesSlice.name ]: conferencesSlice.reducer,
  [ actsSlice.name ]: actsSlice.reducer,
  [ informationSlice.name ]: informationSlice.reducer,
  [ statisticSlice.name ]: statisticSlice.reducer,
  [ testsSlice.name ]: testsSlice.reducer,
  [ searchSlice.name ]: searchSlice.reducer,
  [ ourprojectsSlice.name ]: ourprojectsSlice.reducer,
};
