import { createAsyncThunk, createSlice, } from '@reduxjs/toolkit';
import { REQUEST_STATUSES, } from 'common/constants';
import { addQueryCases, } from 'common/helpers';

const name = 'employers';

const ENDPOINTS = { EMPLOYERS: '/api/v1/employees/', };

export const getEmployers = createAsyncThunk(
  `${name}/getEmployers`,
  async (params, { extra: api, }) => {
    const response = await api.get(ENDPOINTS.EMPLOYERS, { params, });
    return response.data;
  }
);

const initialState = {
  employersStatus: REQUEST_STATUSES.NOT_REQUESTED,
  employers: [],
  employersError: null,
};

const employesSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers(builder) {
    addQueryCases(builder, getEmployers, {
      status: 'employersStatus',
      data: 'employers',
      error: 'employersError',
      options: { concat: false, },
    });
  },
});

export default employesSlice;
