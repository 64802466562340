// components
import { Box, Divider, useMediaQuery, } from '@mui/material';
import Empty from 'components/Empty/Empty';
import { NewsCard, } from 'components/NewsCard';
import { SkeletonCard, } from 'components/SkeletonCard';
// hooks
import { Fragment, useEffect, } from 'react';
import { useDispatch, useSelector, } from 'react-redux';
import { getInformation, } from 'redux-api/slices/informationSlice';
import { getNewsList, } from 'redux-api/slices/newsSlice';

import classes from './newsList.module.scss';

const NewsList = (props) => {
  const {
    sliceName = 'news',
    listOf = 'news',
    variant = 'grid',
    count = 8,
    itemProps = { variant: 'flexCard', button: false, titleLength: 80, },
    itemDivider = false,
    page='news',
  } = props;
  const dispatch = useDispatch();
  const isTable = useMediaQuery('(max-width: 820px)');

  const data = useSelector(state => state[ sliceName ][ listOf ]);
  const status = useSelector(state => state[ sliceName ][ listOf + 'Status' ]);

  useEffect(() => {
    if (data?.results?.length) return;
    const params = {
      limit: count,
      offset: 0,
    };

    if (listOf === 'newsList') {
      dispatch(getNewsList(params));
      return;
    }

    dispatch(getInformation(params));

    return () => {};
    // eslint-disable-next-line
  }, [ getNewsList, ]);

  const skeletonItemsCount = count >= 100 ? 10 : count;

  if (status === 'requested' || status === 'notRequested') {
    return (
      <Box className={classes[ variant ]}>
        {[ ...Array(skeletonItemsCount), ].map((i, idx) => <SkeletonCard className={classes[ itemProps.variant ]} key={idx} height={'200px'}/>)}
      </Box>
    );
  }


  return (
    <Box className={classes[ variant ]}>
      {data?.results?.slice(0, count)?.map((item) =>
        <Fragment key={item.id}>
          { isTable
            ?
            <NewsCard {...itemProps} item={item} page={page}/>
            :
            <NewsCard {...itemProps} variant={itemProps.variant} item={item} page={page}/>
          }
          {itemDivider && <Divider sx={{ width: '100%', }}/>}
        </Fragment>
      )}
      { !data?.count && <Empty/> }
    </Box>
  );
};

export default NewsList;
