import React from 'react';
import { Container, Typography, } from '@mui/material';
import { useEffect, } from 'react';
import { useDispatch, } from 'react-redux';
import { Link, useParams, } from 'react-router-dom';
import { useMediaQuery, } from '@mui/material';

import { NewsDescription, } from 'components/NewsDescription';
import { NewsList, } from 'components/NewsList';
import { BreadCrumbs, } from 'components/BreadCrumbs';
import { useSelector, } from 'react-redux';

import classes from './newsId.module.scss';
import { getNews, getNewsMore, } from 'redux-api/slices/newsSlice';
import { useTranslation, } from 'react-i18next';

const NewsId = () => {
  const isMobile = useMediaQuery('(max-width: 575px');
  const dispatch = useDispatch();
  const { id, } = useParams();
  const newsListLimit = isMobile ? 3 :4;
  const { news, } = useSelector(state => state.news);
  const { t, } = useTranslation();
  const link = <Link to="/news" style={{ textDecoration: 'none', }}>
    <Typography className={classes.prevPath}>
      {t('news')} /
    </Typography>
  </Link>;
  const path = isMobile ? `${news?.title?.slice(0, 16)}` : `${news.title?.slice(0, 80)}`;

  useEffect(() => {
    dispatch(getNews(id))
      .then((data) => {
        dispatch(getNewsMore(data.payload.id));
      });
    return () => {};
  }, [ dispatch, id, ]);

  return (
    <Container>
      <BreadCrumbs path={path} prevPath={link}/>
      <NewsDescription/>
      <Typography className={classes.title}>
        {t('otherNews')}
      </Typography>
      <NewsList variant='row' count={newsListLimit} listOf='newsMore'/>
    </Container>
  );
};

export default NewsId;
