import React from 'react';
import { useMediaQuery, } from '@mui/material';
import { YoutubeList, } from 'components/YoutubeList';

const Conferences = () => {

  const isMobile = useMediaQuery('(max-width: 575px)');
  const conferencesLimit = isMobile ? 2 : 3;

  return (
    <YoutubeList limit={conferencesLimit}/>
  );
};

export default Conferences;
