import React from 'react';
import { Link, } from 'react-router-dom';


const NavigationLink = (props) => {
  const { name, url, classess, } = props;

  return (
    <>
      <Link to={url} className={classess}>{name}</Link>
    </>
  );

};

export default NavigationLink;
