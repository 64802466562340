// components
import { Box, Container, Divider, useMediaQuery, } from '@mui/material';
import { BreadCrumbs, } from 'components/BreadCrumbs';
import { EmployeeCard, } from 'components/EmployeeCard';
import { HTag, } from 'components/HTag';
import { NewsCard, } from 'components/NewsCard';
import { VoteList, } from 'components/VoteList';
import { YoutubeCard, } from 'components/YoutubeCard';
import DecreesCard from 'components/decrees/DecreesCard/DecreesCard';
import AccordionComponent from 'components/UI/Accordion/AccordionComponent';
import { getSearch, } from 'redux-api/slices/searchSlice';

// hooks
import React, { useEffect, useState, } from 'react';
import { useSelector, } from 'react-redux';
import { useDispatch, } from 'react-redux';

import classes from './searchresult.module.scss';
import { useTranslation, } from 'react-i18next';

const SearchResult = () => {
  const { search, searchStatus, searchValue, } = useSelector(state => state.search);
  const isMobile = useMediaQuery('(max-width: 575px)');
  const isTable = useMediaQuery('(max-width: 820px)');
  const [ expanded, setExpanded, ] = useState(false);
  const [ isResult, setIsResult, ] = useState(true);
  const { t, } = useTranslation();
  const dispatch = useDispatch();
  // options for youtube components;s
  const opts = {
    width: '100%',
    height: isMobile ? '220px' : '280px',
  };

  // results are not found
  const notFound = search?.results ? !!(Object.values(search?.results)?.filter(e => !!e.length).length) : false;

  useEffect(() => {
    if (search.overall_total === 0) {
      const count = [];
      Object.keys(search.results).forEach((item) => {
        if (search.results[ item ].length !== 0) {
          count.push(search.results[ item ].length);
        }
      });
      if (count.length === 0) setIsResult(false);
    } else {
      setIsResult(true);
    }
  }, [ search,
    searchValue,
    searchStatus, ]);


  useEffect(() => {
    const search = JSON.parse(localStorage.getItem('search'));
    dispatch(getSearch({ search, }));
  }, [ dispatch, ]);

  const handleChange = (panel) => (_, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  if (!isResult || !notFound) {
    return (
      <Container>
        <Box className={classes.noResult}>
          {t('noResultsPart1')} <strong>{searchValue}</strong> {t('noResultsPart2')}
        </Box>
      </Container>
    );
  }

  return (
    <Container>
      <BreadCrumbs path='Результаты'/>

      {search?.results?.Vote?.length !== 0 && (
        <Box>
          <HTag tag={'h3'}>Голосование</HTag>
          <VoteList list={search?.results?.Vote} status={searchStatus} mainColor='#0770B8' btnHover='#50B227'/>
        </Box>
      )}


      {search?.results?.Tests?.length !== 0 && (
        <Box>
          <HTag tag={'h3'}>Тестирование</HTag>
          <VoteList list={search?.results?.Tests} status={searchStatus} mainColor='#0770B8' btnHover='#50B227'/>
        </Box>
      )}

      {search?.results?.Resolutions?.length !== 0 && (
        <Box>
          <HTag tag={'h3'}>Постановления</HTag>
          {
            search?.results?.Resolutions?.map((item, i) =>
              <DecreesCard
                key={i}
                number={item.number}
                text={item.description}
                date={item.created_at}
                link={item.file}
              />
            )
          }
        </Box>
      )}

      {search?.results?.EmployeeCard?.length !== 0 && (
        <Box>
          <HTag tag={'h3'}>Сотрудники</HTag>
          <Box className={classes.teamList}>
            {search?.results?.EmployeeCard?.map((employee, i) =>
              <EmployeeCard key={i} employee={employee}/>
            )}
          </Box>
        </Box>
      )}


      {search?.results?.Conferences?.length !== 0 && (
        <Box>
          <HTag tag={'h3'}>Видеоматериалы</HTag>
          <Box className={classes.conferenceList}>
            {search?.results?.Conferences?.map((conference, i) => (
              <YoutubeCard key={i} conference={conference} opts={opts}/>
            ))}
          </Box>
        </Box>
      )}


      {search?.results?.News?.length !== 0 && (
        <Box>
          <HTag tag={'h3'}>Новости</HTag>
          <Box className={classes.flex}>
            {
              search?.results?.News?.map((news) =>
                isTable
                  ?
                  <NewsCard variant='flexCard' item={news}/>
                  :
                  <>
                    <NewsCard variant='columnCard' item={news} titleLength={200}/>
                    <Divider sx={{ width: '100%', }}/>
                  </>
              )
            }
          </Box>
        </Box>
      )}


      {search?.results?.Vacancy?.length !== 0 && (
        <Box>
          <HTag tag={'h3'}>Вакансии</HTag>
          { search?.results?.Vacancy?.map((acc) => <AccordionComponent title={acc.title} description={acc.description} key={acc.id} id={acc.id} handleChange={handleChange} expanded={expanded} />) }
        </Box>
      )}


      {search?.results?.Question?.length !== 0 && (
        <Box>
          <HTag tag={'h3'}>Вопросы</HTag>
          {
            search?.results?.Question?.map((item, id) =>
              <AccordionComponent
                key={id}
                title={item?.question}
                description={item?.answer}
                id={id}
                handleChange={handleChange}
                expanded={expanded} />)
          }
        </Box>
      )}
    </Container>
  );
};

export default SearchResult;
