import { useMediaQuery, } from '@mui/material';
import { Box, Container, } from '@mui/system';
import { SkeletonCard, } from 'components/SkeletonCard';
import { YoutubeCard, } from 'components/YoutubeCard';
import React, { useEffect, } from 'react';
import Empty from 'components/Empty/Empty';
import { useDispatch, useSelector, } from 'react-redux';
import { getConferences, } from 'redux-api/slices/conferencesSlice';


import classes from './youtube.module.scss';

const YoutubeList = ({ limit, }) => {
  const isMobile = useMediaQuery('(max-width: 575px)');
  // options for youtube components;s
  const opts = {
    width: '100%',
    height: isMobile ? '220px' : '280px',
  };

  const skeletonHeight = (+(opts.height.split('px')[ 0 ]) + 30)+'px';

  const dispatch = useDispatch();
  const { conferences, } = useSelector(state => state.conferences);
  const status = useSelector(state => state.conferences.conferencesStatus);
  const conferenceItems = conferences?.results;

  useEffect(() => {
    dispatch(getConferences({ limit, offset: 0, }));

    return () => {};
    // eslint-disable-next-line
  }, []);

  if (status === 'requested' || status === 'notRequested') {
    return (
      <Container>
        <Box className={classes.conferenceList}>
          {[ ...Array(limit), ].map((i) => <SkeletonCard className={classes.conferenceItem} key={i} height={skeletonHeight}/>)}
        </Box>
      </Container>
    );
  }

  return (
    <Box className={classes.conferenceList}>
      {conferenceItems && conferenceItems.map((conference, i) => (
        <YoutubeCard key={i} conference={conference} opts={opts}/>
      ))}
      {!conferences?.count && <Empty/>}
    </Box>
  );
};

export default YoutubeList;
