import { Box, Typography, } from '@mui/material';
// Hooks
import { useTranslation, } from 'react-i18next';

import about from '../../assets/images/about.png';
import classes from './info.module.scss';

const InfoAbout = () => {

  const { t, } = useTranslation();

  const column = [
    { text: t('infoAboutColumnZero'), },
    { text: t('infoAboutColumnOne'), },
    { text: t('infoAboutColumnTwo'), },
    { text: t('infoAboutColumnThree'), },
    { text: t('infoAboutColumnFive'), },
  ];

  return (
    <Box className={classes.textWrapper}>
      <Typography className={classes.title}>{t('about')}</Typography>
      <Box className={classes.textContent}>
        <img className={classes.aboutImg} src={about} alt="" ></img>
        <Typography component={'p'}>
          {t('infoAboutContentOne')}
        </Typography>
        <Typography component={'p'}>
          {t('infoAboutContentTwo')}
        </Typography>
        <Box className={classes.customParagraph}>
          <Typography className={classes.desc} component={'p'} >
            {t('infoAboutContentThree')}
          </Typography>
          <Typography>
            {t('infoAboutContentFour')}
          </Typography>
          {column.map((column, idx) => (
            <Typography>{column.text}</Typography>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default InfoAbout;
