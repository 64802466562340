import React from 'react';
import { keyframes, } from '@emotion/react';

import { IconButton, } from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

const CallButton = () => {
  const pulseAnimation = keyframes`
    70% {
      box-shadow: 0 0 0 35px rgba(1, 71, 130, 0);
    }
    100% {
      box-shadow: 0 0 0 0 rgba(1, 71, 130, 0);
    }
  `;
  return (
    <IconButton
      sx={{
        position: 'fixed',
        bottom: 52,
        right: 46,
        width: 56,
        height: 56,
        backgroundColor: 'rgb(37, 211, 102)',
        color: 'rgb(252, 252, 253)',
        zIndex: 1111111,
        boxShadow: '0 0 0 0 rgba(1, 71, 130, 0.5)',
        animation: `${pulseAnimation} 1.5s linear infinite`,
        animationDelay: '1.5s',

        '&:hover': { backgroundColor: 'rgb(37, 211, 102)', },
      }}
      LinkComponent="a"
      href="https://api.whatsapp.com/send?phone=996500626150"
      target="_blank"
      rel='noreferrer'
    >
      <WhatsAppIcon />
    </IconButton>
  );
};

export default CallButton;
