import React from 'react';
import { Box, Container, } from '@mui/material';
import Selects from 'components/decrees/Selects/Selects';
import { useTranslation, } from 'react-i18next';

import { DecreesList, } from 'components/decrees/DecreesList';
import { BreadCrumbs, } from 'components/BreadCrumbs';
import { HTag, } from 'components/HTag';

const Decrees = () => {
  const { t, } = useTranslation();

  return (
    <Box>
      <Container>
        <BreadCrumbs path={t('decrees')}/>
        <HTag tag='h2'>
          {t('decrees')}
        </HTag>
        <Selects />
        <DecreesList />
      </Container>
    </Box>
  );
};

export default Decrees;
