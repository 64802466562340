import { Box, Typography, } from '@mui/material';
import { useEffect, useState, } from 'react';
import { useTranslation, } from 'react-i18next';
import { Link, } from 'react-router-dom';

import classes from './voteCard.module.scss';

const VoteCard = ({ vote, mainColor, btnHover, bgHover, }) => {
  const descLength = 50;
  const [ text, setText, ] = useState('');
  const { t, } = useTranslation();

  useEffect(() => {
    if (vote?.description !== undefined) {
      setText(vote?.description.length > descLength ? vote?.description.slice(0, descLength) + '...' : vote?.description);
    } else {
      setText(vote.title);
    }
  }, [ vote, ]);
  return (
    <Box className={classes.voteCard} sx={{
      background: mainColor,
      border: `1px solid ${mainColor}`,
      '& p': { color: '#fff', },
      '&:hover': {
        background: `${bgHover}`,
        border: `1px solid ${mainColor}`,
        '& p': { color: 'white !important', },
        '& .hoverBtn': { background: `${btnHover} !important`, color: btnHover ? '#fff' : mainColor, },
      },
      '& .hoverBtn': {
        color: mainColor,
        border: `1px solid ${mainColor}`,
        transition: 'all 0.3s ease',
      },
    }}>
      <Box className={classes.insideVoteCard}>
        <Box className={classes.text}>
          <Typography>{text.length > descLength ? text.substring(0, descLength) + '...' : text}</Typography>
        </Box>
        {vote.link
          ?
          <Box component={'a'} href={vote.link} target='_blank' className={classes.linkBtn + ' hoverBtn'}>{t('passTest')}</Box>
          :
          <Link to={`/vote/${vote.id}`} className={classes.linkBtn + ' hoverBtn'}>{t('passTest')}</Link>
        }
      </Box>
    </Box>
  );
};

export default VoteCard;
